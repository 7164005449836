import IconSelected from 'UI/IconSelected';
import getFormattedDollarPrice from 'shared/getters/getFormattedDollarPrice';
import { useState } from 'react';
import ShowMorePopup from 'UI/Popup/ShowMorePopup';
import useTranslation from 'shared/useTranslation';
import * as S from './styles';
import { AppointmentComponentProps } from '../../types';

const AppointmentComponent = ({
  description,
  id,
  isSelected,
  name,
  price,
  priceText,
  svgUrl,
  onClick,
}: AppointmentComponentProps) => {
  const [translations] = useTranslation([
    'widget.step.appointment.free.options.title',
    'widget.step.appointment.popup.show.more',
  ] as const);

  const [openShowMore, setOpenShowMore] = useState(false);

  const onOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setOpenShowMore(true);
  };

  const onClose = () => setOpenShowMore(false);

  const onAppointmentClick = () => {
    onClick(id);
  };

  const renderAsLowAs = () => {
    if (price === 0) {
      return <>{translations.widgetStepAppointmentFreeOptionsTitle}</>;
    }

    return (
      <>
        {priceText} <S.AsLowAsPrice>{getFormattedDollarPrice(price)}</S.AsLowAsPrice>
      </>
    );
  };

  return (
    <>
      <ShowMorePopup onClose={onClose} isOpen={openShowMore}>
        <S.ShowMoreInformationWrapper>
          <S.ShowMoreImageWrapper>
            <S.ShowMoreImage src={svgUrl} />
          </S.ShowMoreImageWrapper>
          <S.ShowMoreTitle>{name}</S.ShowMoreTitle>
          <S.ShowMoreDescription>{description}</S.ShowMoreDescription>
        </S.ShowMoreInformationWrapper>
      </ShowMorePopup>
      <S.Wrapper onClick={onAppointmentClick}>
        <IconSelected selected={isSelected} />
        <S.AppointmentInformationWrapper>
          <S.ImageWrapper>
            <S.Image src={svgUrl} />
          </S.ImageWrapper>
          <S.InformationWrapper>
            <S.Title>{name}</S.Title>
            <S.Description>{description}</S.Description>
          </S.InformationWrapper>
        </S.AppointmentInformationWrapper>
        <S.AppointmentFooterWrapper>
          <S.AsLowAsWrapper>
            <S.AsLowAsText>{renderAsLowAs()}</S.AsLowAsText>
            <S.ShowMoreButton onClick={onOpen}>{translations.widgetStepAppointmentPopupShowMore}</S.ShowMoreButton>
          </S.AsLowAsWrapper>
        </S.AppointmentFooterWrapper>
      </S.Wrapper>
    </>
  );
};

export default AppointmentComponent;
