import IconSelected from 'UI/IconSelected';
import { IndustryComponentProps } from '../../types';
import * as S from './styles';

const IndustryComponent = ({ id, isSelected, name, price, svgUrl, onClick }: IndustryComponentProps): JSX.Element => {
  const onIndustryClick = () => {
    onClick(id);
  };

  return (
    <S.Container onClick={onIndustryClick}>
      <IconSelected selected={isSelected} />
      <S.PriceContainer>
        <S.PriceLabel>{price}</S.PriceLabel>
      </S.PriceContainer>
      <S.ImageWrapper>
        <S.Image src={svgUrl} />
      </S.ImageWrapper>
      <S.Title>{name}</S.Title>
    </S.Container>
  );
};

export default IndustryComponent;
