import { Appointment } from 'store/appointment/types';

// Note: some data is mocked right now.
const APPOINTMENT_SVG_URL = `${process.env.PUBLIC_URL}/icons/window-viper.svg`;

export function getAppointmentsFormatted(appointments: Appointment[]) {
  return appointments.map((appointment) => ({
    id: appointment.id,
    asLowPrice: appointment.asLowPrice,
    description: appointment.description,
    name: appointment.displayName,
    svgUrl: appointment.icon ?? APPOINTMENT_SVG_URL,
  }));
}
