import { WidgetContext } from 'Pages/Widget/Widget.constants';
import { WidgetActionType } from 'Pages/Widget/Widget.store';
import { useGetBookingPayload } from 'Pages/Widget/Widget.utils';
import { useCallback, useContext, useState, useEffect } from 'react';
import { useGetClientCreditCardInfoQuery } from 'store/client/client.api';
import { usePayMutation, useGetClientSecretMutation } from 'store/widget/widget.api';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import toast from 'react-hot-toast';
import { isServerErrorWithMessage, isStripeErrorMessage } from 'shared/ErrorTypeCheck';
import useTranslation from 'shared/useTranslation';
import { CREDIT_CARD_ID_NONE } from './constants';
import { getInitCreditCardIds } from './utils';

export const useStripePaymentFormHook = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [pay] = usePayMutation();
  const [getClientSecret] = useGetClientSecretMutation();
  const [translations] = useTranslation([
    'widget.step.payment.failed.create.payment.method',
    'widget.step.payment.something.is.wrong',
    'widget.step.payment.stripe.is.not.available',
  ] as const);

  return useCallback(
    async (dataForQuery: {}) => {
      const cardEl = elements?.getElement(CardElement);

      if (!stripe || !elements || !cardEl) {
        throw new Error(translations.widgetStepPaymentStripeIsNotAvailable);
      }

      const stripeResponse = await stripe.createPaymentMethod({
        type: 'card',
        card: cardEl,
      });

      const { error, paymentMethod } = stripeResponse;
      if (error || !paymentMethod) {
        throw new Error(translations.widgetStepPaymentFailedCreatePaymentMethod);
      }

      const response = await getClientSecret(paymentMethod.id).unwrap();
      const clientSecret = response.client_secret;
      const result = await stripe?.confirmCardSetup(clientSecret);

      if (result.error) {
        const { message } = result.error;
        if (message) {
          throw new Error(message);
        }
        throw new Error(translations.widgetStepPaymentSomethingIsWrong);
      }
      return pay({ ...dataForQuery, creditCardId: paymentMethod?.id! }).unwrap();
    },
    [
      elements,
      getClientSecret,
      pay,
      stripe,
      translations.widgetStepPaymentFailedCreatePaymentMethod,
      translations.widgetStepPaymentSomethingIsWrong,
      translations.widgetStepPaymentStripeIsNotAvailable,
    ],
  );
};

export const useProceedPayment = ({ onError }: { onError?: (error: unknown) => void }) => {
  const [translations] = useTranslation(['common.form.unsuccessful'] as const);
  const { state, dispatch } = useContext(WidgetContext);
  const [pay] = usePayMutation();
  const payWithCardNew = useStripePaymentFormHook();

  const payload = useGetBookingPayload(state);

  const proceedPayment = useCallback(
    async (selectedPaymentCardId: string) => {
      try {
        // Note: if the price is zero we don't send `creditCardId` field to the server
        if (payload.price === 0) {
          await pay(payload).unwrap();
        } else {
          await (selectedPaymentCardId === CREDIT_CARD_ID_NONE
            ? payWithCardNew(payload)
            : pay({ ...payload, creditCardId: selectedPaymentCardId }).unwrap());
        }
        dispatch({ type: WidgetActionType.GO_TO_PAYMENT_SUCCESS });
      } catch (error) {
        onError?.(error);

        if (isStripeErrorMessage(error)) {
          toast.error(error.message);
          return;
        }

        if (isServerErrorWithMessage(error)) {
          toast.error(error.data.message);
          return;
        }
        toast.error(translations.commonFormUnsuccessful);
      }
    },
    [dispatch, pay, payWithCardNew, payload, translations.commonFormUnsuccessful],
  );

  return proceedPayment;
};

export const useGetPaymentCards = () => {
  const { data: cards = [], isFetching } = useGetClientCreditCardInfoQuery();
  const initialDefaultCard = getInitCreditCardIds(cards);

  const [selectedPaymentCardId, setSelectedPaymentCardId] = useState(initialDefaultCard);

  useEffect(() => {
    if (cards.length > 0 && !isFetching) {
      setSelectedPaymentCardId(initialDefaultCard);
    }
  }, [cards, initialDefaultCard, isFetching]);

  const onCardSelect = (id: string) => {
    setSelectedPaymentCardId(id);
  };

  return {
    initialDefaultCard,
    isFetching,
    paymentCards: cards,
    selectedPaymentCardId,
    onCardSelect,
  };
};
