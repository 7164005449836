import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/types';

export const selectAvailableTimeRanges = createSelector(
  (state: RootState) => state.availableTimeRangeReducer.availableTimeRanges,
  (availableTimeRanges) => availableTimeRanges,
);

export const selectHasTimeRangeSelection = createSelector(
  (state: RootState) => state.availableTimeRangeReducer.hasTimeRangeSelection,
  (hasTimeRangeSelection) => hasTimeRangeSelection,
);

export const selectEnableAnytimeTimeRangeSelection = createSelector(
  (state: RootState) => state.availableTimeRangeReducer.enableAnytimeTimeRange,
  (enableAnytimeTimeRange) => enableAnytimeTimeRange,
);
