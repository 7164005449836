import { createTheme, ThemeOptions } from '@mui/material';
import COLORS from 'styles/colors';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: false;
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true;
    frame: true;
    laptop: true;
  }
}

// TODO use Material UI breakpoints instead of mediaqueries
const options = (): ThemeOptions => ({
  breakpoints: {
    values: {
      mobile: 768,
      frame: 960,
      laptop: 1200,
    },
  },
  palette: {
    mode: 'light',
    primary: {
      main: COLORS.cornflowerBlue,
      contrastText: COLORS.white,
    },
    secondary: {
      main: COLORS.grey60,
      contrastText: COLORS.white,
    },
    info: {
      main: COLORS.black,
      contrastText: COLORS.white,
    },
    success: {
      main: COLORS.white,
      contrastText: COLORS.black,
    },
  },

  components: {
    MuiAccordion: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          ':before': {
            display: 'none',
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          minHeight: '40px',
        },
      },
      variants: [
        {
          props: { variant: 'contained', color: 'secondary' },
          style: {
            backgroundColor: COLORS.catskillWhite,
            color: COLORS.black,
            '&:hover': {
              backgroundColor: COLORS.grey10,
            },
          },
        },
      ],
    },
  },
  typography: {
    fontFamily: 'Outfit',
    button: {
      textTransform: 'none',
      fontWeight: 500,
      fontSize: '16px',
    },
  },
});

export const mainTheme = createTheme(options());
