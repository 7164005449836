import React, { useContext } from 'react';
import { WidgetActionType } from 'Pages/Widget/Widget.store';
import { WidgetContext } from 'Pages/Widget/Widget.constants';
import { getWidgetStepTitleTranslated, normalise } from 'Pages/Widget/Widget.utils';
import { useMediaQuery } from 'shared/useMediaQuery';
import { RESOLUTIONS } from 'utils/responsive';
import useTranslation from 'shared/useTranslation';
import { useGetIndustryId } from 'Pages/Widget/Widget.hooks';
import { WidgetStep } from 'Pages/Widget/Widget.types';
import * as S from './TopBar.styles';
import { ProgressBar } from '../index';
import { formatStepTitle } from './utils';

const TopBar = (): JSX.Element => {
  const { state, dispatch } = useContext(WidgetContext);
  const isMobile = useMediaQuery(RESOLUTIONS.MOBILE_RESOLUTION);
  const [translations, t] = useTranslation(['common.btn.back', 'common.label.empty'] as const);
  const { currentStep, widgetSteps, selectedAppointmentType } = state;
  const industryId = useGetIndustryId();

  const handleGoBack = () => dispatch({ type: WidgetActionType.PREVIOUS_STEP });
  const showBackButton = industryId
    ? ![WidgetStep.INDUSTRY, WidgetStep.APPOINTMENT].includes(currentStep)
    : currentStep !== WidgetStep.INDUSTRY;

  const previousStepIndex = currentStep - 1;
  const previousStep = showBackButton ? widgetSteps[previousStepIndex] : null;
  const previousStepTitle = previousStep
    ? getWidgetStepTitleTranslated(t, previousStep.type, { appointmentType: selectedAppointmentType })
    : '';

  const renderBreadCrumbs = (step: number) => {
    if (step === WidgetStep.INDUSTRY && isMobile) {
      return null;
    }
    if (step === WidgetStep.SUMMARY && !isMobile) {
      return null;
    }
    if (isMobile) {
      if (previousStep) {
        return (
          <S.StepLine>
            <S.Step key={previousStepIndex} isPassed>
              <img
                alt={translations.commonBtnBack}
                height={16}
                src={`${process.env.PUBLIC_URL}/icons/back-arrow-mobile.svg`}
                onClick={handleGoBack}
              />{' '}
              {formatStepTitle(previousStepTitle, currentStep)}
            </S.Step>
          </S.StepLine>
        );
      }
      return null;
    }
    return (
      <S.StepLine>
        {widgetSteps.map((step) => {
          const title = getWidgetStepTitleTranslated(t, step.type, { appointmentType: selectedAppointmentType });
          return (
            <S.Step key={step.type} isPassed={currentStep + 1 <= step.type}>
              {formatStepTitle(title, step.type)}
            </S.Step>
          );
        })}
      </S.StepLine>
    );
  };

  return (
    <S.Container>
      <ProgressBar currentStep={normalise(currentStep + 1, state)} />
      {renderBreadCrumbs(currentStep)}
    </S.Container>
  );
};

export default TopBar;
