import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/types';

export const selectAppointments = createSelector(
  (state: RootState) => state.appointmentReducer.appointments,
  (appointments) => appointments,
);

export const selectAppointmentByAppointmentId = createSelector(
  (state: RootState) => state.appointmentReducer.appointments,
  (_: RootState, appointmentId: number | undefined) => appointmentId,
  (appointments, appointmentId) => appointments.find((appointment) => appointment.id === appointmentId),
);

export const selectAppointmentsByIndustryId = createSelector(
  (state: RootState) => state.appointmentReducer.appointments,
  (_: RootState, industryId: number | undefined) => industryId,
  (appointments, industryId) => appointments.filter((appointment) => appointment.industryId === industryId),
);
