import styled from '@emotion/styled';
import colors from 'styles/colors';
import { Typography } from '@mui/material';

const INDUSTRY_BLOCK_HEIGHT = 168;

export const Container = styled.div`
  background-color: ${colors.white};
  border-radius: 8px;
  box-shadow: 0px 4px 10px ${colors.blackSqueeze};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: ${INDUSTRY_BLOCK_HEIGHT}px;
  padding: 8px;
  position: relative;
  width: 100%;

  &:hover {
    cursor: pointer;
  }
`;

export const PriceContainer = styled.div`
  display: inline-flex;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 2px 0;
`;

export const PriceLabel = styled(Typography)`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  background-color: ${colors.grey10};
  border-radius: 4px;
  color: ${colors.cornflowerBlue};
  display: -webkit-box;
  font-size: 10px;
  font-weight: 500;
  line-height: 24px;
  max-width: 100%;
  overflow: hidden;
  padding: 0 4px;
  text-overflow: ellipsis;
`;

export const ImageWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  margin: 0 auto;
  overflow: hidden;
  width: 100%;
  padding: 10px;
`;

export const Image = styled.img`
  color: ${colors.cornflowerBlue};
  height: 100%;
`;

export const Title = styled.div`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  flex-grow: 0;
  flex-shrink: 0;
  max-height: 44px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 1.2;
  overflow: hidden;
  text-overflow: ellipsis;
`;
