import { Industry } from 'store/industry/types';

// Note: some data is mocked right now.
const INDUSTRY_SVG_URL = `${process.env.PUBLIC_URL}/icons/handyman.svg`;

export function getIndustryFormatted(industries: Industry[]) {
  return industries.map((industry) => ({
    appointmentIds: industry.appointmentIds,
    asLowPrice: industry.asLowPrice,
    id: industry.id,
    name: industry.name,
    svgUrl: INDUSTRY_SVG_URL,
  }));
}
