import styled from '@emotion/styled';

import { FormControl as MuiFormControl, FormHelperText, Select as MuiSelect } from '@mui/material';
import colors from 'styles/colors';
import { CUSTOM_LABEL } from './Select.constants';
import { INPUT_PLACEHOLDER_CLASS } from '../InputPlaceholder/InputPlaceholder.types';
import PropertyTitle from '../PropertyTitle';

export const Container = styled.div`
  flex: 1;
  overflow: visible;
`;

export const MarginOffTitle = styled(PropertyTitle)`
  display: flex;
  flex-basis: 100%;
  margin: 0 0 8px;
`;

export const FormControl = styled(MuiFormControl)`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  overflow: visible;

  & .${CUSTOM_LABEL} {
    background: white;
    top: -7px;
  }

  & .${CUSTOM_LABEL}.MuiInputLabel-shrink {
    top: 0;
    padding-left: 4px;
    padding-right: 4px;
  }

  & .${INPUT_PLACEHOLDER_CLASS} {
    color: ${({ error }) => (error ? colors.error100 : colors.grey70)};
  }
`;

export const SelectHelperText = styled(FormHelperText)`
  margin: 4px 14px 0;
`;

export const Select = styled(MuiSelect)`
  width: 100%;
  height: 100%;

  & .MuiSelect-select {
    padding-bottom: 8px;
    padding-top: 8px;
  }
`;
