import { useParams } from 'react-router-dom';
import { useGetWidgetDataByAppointmentGroupsQuery } from '../store/widget/widget.api';

export const useFetchWidget = () => {
  const { appointmentGroupId } = useParams<{ appointmentGroupId: string }>();

  return useGetWidgetDataByAppointmentGroupsQuery(appointmentGroupId, {
    skip: !appointmentGroupId,
  });
};
