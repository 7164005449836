import useTranslation from 'shared/useTranslation';
import { ReactComponent as ExclamationCircle } from 'icons/expclamationCircle.svg';
import React, { MouseEvent } from 'react';
import { useMediaQuery } from 'shared/useMediaQuery';
import { RESOLUTIONS } from 'utils/responsive';
import { CardProps } from './Card.types';
import * as S from './Card.styles';
import { getFormattedCardDetail } from './utils';

const Card = ({ cardData, isSelected, onCardClick, onCardDetailsOpen }: CardProps) => {
  const [, t] = useTranslation(['common.field.card.number.expire.date.tooltip.label'] as const);

  const { icon, expiredDate, creditCardExpired, isValid } = getFormattedCardDetail(cardData);
  const isMobile = useMediaQuery(RESOLUTIONS.MOBILE_RESOLUTION);

  const { last4, creditCardId } = cardData;

  const handleCardSelect = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (creditCardExpired) {
      return;
    }
    onCardClick?.(creditCardId);
  };

  const onCardSelect = (e: MouseEvent<HTMLDivElement>) => {
    if (isMobile) {
      onCardDetailsOpen();
    }

    handleCardSelect(e);
  };

  const isCardSelected = isSelected && !creditCardExpired;

  return (
    <>
      <S.Card id="saved-credit-card" onClick={onCardSelect}>
        <S.RadioButtonWrapper onClick={handleCardSelect}>
          <S.CardRadioButton
            checked={isCardSelected}
            disableRipple
            checkedIcon={<S.BpCheckedIcon />}
            icon={<S.BpIcon />}
          />
        </S.RadioButtonWrapper>
        <S.CardImage src={`${process.env.PUBLIC_URL}/icons/cards/${icon}`} />
        <S.CardDetailsWrapper>
          <S.CardNumber>{t('common.field.card.number.placeholder', { last4 })}</S.CardNumber>
          <S.CardExpireDate>{t('common.field.card.number.expire.date', { expiredDate })}</S.CardExpireDate>
        </S.CardDetailsWrapper>
        {creditCardExpired || !isValid ? (
          <S.CreditCardStatus>
            <ExclamationCircle />
          </S.CreditCardStatus>
        ) : null}
      </S.Card>
    </>
  );
};

export default Card;
