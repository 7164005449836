import IconSelected from 'UI/IconSelected';
import getFormattedDollarPrice from 'shared/getters/getFormattedDollarPrice';
import { SelectBlockProps } from './types';
import * as S from './styles';

const SelectTextBlock = ({ id, isSelected, name, price, onClick }: SelectBlockProps): JSX.Element => {
  const onBlockClick = () => {
    onClick(id);
  };

  return (
    <S.SelectBlockContainer onClick={onBlockClick}>
      <IconSelected selected={isSelected} />
      <S.Title>{name}</S.Title>
      <S.SubTitle>{getFormattedDollarPrice(price)}</S.SubTitle>
    </S.SelectBlockContainer>
  );
};

export default SelectTextBlock;
