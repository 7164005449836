import styled from '@emotion/styled';
import COLORS from 'styles/colors';
import { IconSelectedProps } from './types';

export const Wrapper = styled.div<IconSelectedProps>`
  align-items: center;
  background-color: ${COLORS.cornflowerBlue};
  border-bottom-left-radius: 8px;
  border-top-right-radius: 8px;
  display: ${({ selected }) => (selected ? '-webkit-box' : 'none')};
  font-size: 16px;
  font-weight: 500;
  height: 100%;
  justify-content: center;
  max-height: 24px;
  max-width: 24px;
  overflow: hidden;
  position: absolute;
  right: 0;
  text-overflow: ellipsis;
  top: 0;
  white-space: break-spaces;
  width: 100%;
`;

export const Icon = styled.img`
  width: 24px;
`;
