import styled from '@emotion/styled';
import COLORS from 'styles/colors';
import { Radio } from '@mui/material';

export const Card = styled.div`
  align-items: center;
  background-color: ${COLORS.white};
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  font-weight: 500;
  gap: 16px;
  padding: 12px 21px;
  width: 100%;
  border-radius: 0;
`;

export const CardDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const CardNumber = styled.div`
  font-family: Outfit, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: ${COLORS.grey80};
`;

export const CardExpireDate = styled.div`
  font-family: Outfit, serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.2px;
  text-transform: uppercase;
  color: ${COLORS.grey60};
`;

export const CardImage = styled.img`
  width: 42px;
`;

export const CreditCardStatus = styled.div`
  align-items: center;
  display: flex;
  height: 20px;
  justify-content: center;
  margin-left: auto;
  width: 20px;

  @media (max-width: 768px) {
    height: 24px;
    width: 24px;
  }

  svg {
    flex-shrink: 0;
  }
`;

export const RadioButtonWrapper = styled.div``;

export const CardRadioButton = styled(Radio)`
  padding: 0;
`;

export const BpIcon = styled('span')({
  borderRadius: '50%',
  width: 16,
  height: 16,
  boxShadow: `0 0 0 0.5px ${COLORS.grey300}`,
  backgroundColor: COLORS.grey50,
  'input:checked ~ &': {
    boxShadow: 'none',
  },
});

export const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: COLORS.primary600,
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage: 'radial-gradient(#fff,#fff 35%,transparent 30%)',
    content: '""',
  },
});
