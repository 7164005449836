import { Dialog } from '@mui/material';
import styled from '@emotion/styled';
import colors from 'styles/colors';

import { ReactComponent as HomeIndicator } from 'icons/home-indicator.svg';
import CloseIcon from '@mui/icons-material/Close';

export const DialogWrapper = styled(Dialog)`
  & .MuiPaper-root {
    background-color: transparent;
    border-radius: 8px;
    box-shadow: 0 4px 14px 0 ${colors.grey500};
    min-width: 396px;
  }

  & .MuiDialogContent-root {
    padding: 0 16px 16px 16px;
  }

  @media (max-width: 500px) {
    & .MuiPaper-root {
      border-radius: 12px;
      height: 100%;
      margin-bottom: 0;
      margin-top: 50vh;
      max-height: 100%;
      min-width: 100%;
    }
  }
`;

export const DialogContentWrapper = styled.div<{ moveDown: number | null }>`
  background-color: ${colors.white};
  height: 100%;
  transform: ${({ moveDown }) => (moveDown ? `translateY(${moveDown}%)` : 'none')};
  transition: all 0.2s ease;
  border-radius: 8px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 16px 16px 0 0;

  @media (max-width: 500px) {
    display: flex;
    justify-content: center;
    padding: 7px 0 10px 0;
  }
`;

export const HomeIndicatorIcon = styled(HomeIndicator)`
  display: none;
  @media (max-width: 500px) {
    display: block;
  }
`;

export const Close = styled(CloseIcon)`
  cursor: pointer;

  @media (max-width: 500px) {
    display: none;
  }
`;
