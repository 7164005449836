import styled from '@emotion/styled';

export const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;

  @media (max-width: 768px) {
    margin-top: 0px;
    margin-left: 0px;
  }
`;
