import React, { useEffect, useState } from 'react';
import { Outlet, Route, Routes, useSearchParams } from 'react-router-dom';
import { useAppSelector } from 'hooks/hooks';
import Widget from 'Pages/Widget/Widget.component';
import { CircularProgress } from '@mui/material';
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import { selectAccessToken } from 'store/auth/auth.selectors';
import App from './App';
import { TOKEN_SEARCH_NAME } from './Pages/LoginPage/types';
import { setAccessToken } from './store/auth/auth.slice';
import useTranslation from './shared/useTranslation';
import { useLoginByTokenMutation } from './store/auth/auth.api';
import LoginPage from './Pages/LoginPage';

export enum RoutesEnum {
  root = '/',
  login = '/login',
}

const RequireAuth = () => {
  const isLoggedIn = useAppSelector(selectAccessToken);
  return isLoggedIn ? <Outlet /> : <LoginPage />;
};

const TokenLogin = ({ token }: { token: string }) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [loginByToken] = useLoginByTokenMutation();
  const dispatch = useDispatch();
  const [translations] = useTranslation(['login.form.sign.in.token.error'] as const);

  useEffect(() => {
    dispatch(setAccessToken(token));
    loginByToken()
      .unwrap()
      .then(() => {
        setIsLoggedIn(true);
      })
      .catch(() => {
        setIsLoggedIn(false);
        toast.error(translations.loginFormSignInTokenError);
      });
  }, [dispatch, loginByToken, token, translations.loginFormSignInTokenError]);

  return isLoggedIn ? (
    <Outlet />
  ) : (
    <div className="app-loader">
      <CircularProgress />
    </div>
  );
};

const ProtectedRoutes = () => {
  const [searchParams] = useSearchParams();

  const token = searchParams.get(TOKEN_SEARCH_NAME);

  return token ? <TokenLogin token={token} /> : <RequireAuth />;
};

export default (
  <Routes>
    <Route element={<ProtectedRoutes />}>
      <Route path={`${process.env.PUBLIC_URL}/:appointmentGroupId`} element={<Widget />} />
      <Route path={`${process.env.PUBLIC_URL}/logout`} element={<App />} />
    </Route>
  </Routes>
);
