import { ReactComponent as CrossIcon } from 'icons/cross.svg';
import React from 'react';
import { RESOLUTIONS } from 'utils/responsive';
import { useMediaQuery } from 'shared/useMediaQuery';
import useTranslation from 'shared/useTranslation';
import * as S from './styles';
import StripePaymentForm from '../../../../../StripePaymentForm';
import { CREDIT_CARD_ID_NONE } from '../../../../constants';
import NextButton from '../../../../../Step/atoms/NextButton';
import { AddNewCardPopupProps } from './types';
import { useAddNewCard } from './hooks';

const AddNewCardPopup = ({ open, onClose }: AddNewCardPopupProps) => {
  const [translations] = useTranslation(['widget.step.payment.add.new.card'] as const);
  const { isCardValid, isStripeTokenLoading, onCardChange, onSaveAddCreditCard, isLoading } = useAddNewCard(onClose);
  const isMobile = useMediaQuery(RESOLUTIONS.MOBILE_RESOLUTION);

  return (
    <S.DialogWrapper onClose={onClose} open={open} fullWidth>
      <S.DialogHeader>
        <S.Title>{translations.widgetStepPaymentAddNewCard}</S.Title>
        <CrossIcon onClick={onClose} />
      </S.DialogHeader>
      <S.DialogContentWrapper>
        {isMobile ? <StripePaymentForm id={CREDIT_CARD_ID_NONE} onCardInfoChange={onCardChange} /> : null}
      </S.DialogContentWrapper>
      <S.DialogActionsWrapper>
        <NextButton
          color="primary"
          disabled={!isCardValid}
          loading={isLoading || isStripeTokenLoading}
          variant="contained"
          onClick={onSaveAddCreditCard}
        >
          {translations.widgetStepPaymentAddNewCard}
        </NextButton>
      </S.DialogActionsWrapper>
    </S.DialogWrapper>
  );
};

export default AddNewCardPopup;
