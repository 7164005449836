import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from 'store';
import * as Sentry from '@sentry/react';
import routes from 'routes';
import MaterialProvider from 'Providers/MaterialProvider';
import { initHotjar } from 'utils/initHotjar';
import ToasterProvider from 'Providers/ToasterProvider';
import reportWebVitals from './reportWebVitals';
import 'i18n';
import './index.css';

const Root = () => (
  <MaterialProvider>
    <ToasterProvider>
      <BrowserRouter children={routes} />
    </ToasterProvider>
  </MaterialProvider>
);

const isDevelopment = process.env.NODE_ENV === 'development';
const hotjarId = process.env.REACT_APP_HOTJAR_ID;
const sentryDSN = process.env.REACT_APP_SENTRY_DSN;

if (!isDevelopment && hotjarId) initHotjar(process.env.REACT_APP_HOTJAR_ID);
if (!isDevelopment && sentryDSN) Sentry.init({ dsn: sentryDSN });

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Root />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
