import { useContext } from 'react';
import useTranslation from 'shared/useTranslation';
import { Box, Button } from '@mui/material';
import { BOX_WRAPPER_STYLES } from 'shared/styles';
import { useFetchWidget } from 'hooks/useFetchWidget';
import PaymentStatusWrapper from 'UI/PaymentStatusWrapper';
import { WidgetActionType } from 'Pages/Widget/Widget.store';
import { WidgetContext } from 'Pages/Widget/Widget.constants';

const PaymentSuccessStep = (): JSX.Element => {
  const { dispatch } = useContext(WidgetContext);

  const [translations] = useTranslation([
    'widget.step.payment.success.title',
    'widget.step.payment.success.subtitle',
    'widget.step.payment.success.back',
  ] as const);

  const { refetch: refetchWidget } = useFetchWidget();

  const onBackButtonClick = async () => {
    await refetchWidget();
    dispatch({ type: WidgetActionType.RESTART_WIDGET_CREATION });
  };

  return (
    <PaymentStatusWrapper
      title={translations.widgetStepPaymentSuccessTitle}
      subtitle={translations.widgetStepPaymentSuccessSubtitle}
      icon={`${process.env.PUBLIC_URL}/icons/payment-success.svg`}
    >
      <Box sx={BOX_WRAPPER_STYLES}>
        <Button variant="contained" color="primary" onClick={onBackButtonClick}>
          {translations.widgetStepPaymentSuccessBack}
        </Button>
      </Box>
    </PaymentStatusWrapper>
  );
};

export default PaymentSuccessStep;
