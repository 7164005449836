import { useAppSelector } from 'hooks/hooks';
import { useGetSelectedVariant } from 'hooks/widget';
import { maxBy, round } from 'lodash';
import { TIME_RANGE_ANYTIME_DISCOUNT } from 'shared/timeRanges';
import { selectEnableAnytimeTimeRangeSelection, selectHasTimeRangeSelection } from 'store/availableTimeRange/selectors';
import { selectDiscounts } from 'store/discount/selectors';

export const useGetLowestVariantPrice = () => {
  const selectedVariant = useGetSelectedVariant();
  const discounts = useAppSelector(selectDiscounts);
  const hasTimeRangeEnabled = useAppSelector(selectHasTimeRangeSelection);
  const enableAnytimeRange = useAppSelector(selectEnableAnytimeTimeRangeSelection);

  const highestDiscount = maxBy(discounts, ({ discountPercent }) => discountPercent);

  if (!selectedVariant) {
    return undefined;
  }

  if (!highestDiscount) {
    return undefined;
  }

  const discount = highestDiscount.discountPercent;

  if (discount === 0) {
    return undefined;
  }

  const timeRangeDiscount = hasTimeRangeEnabled && enableAnytimeRange ? TIME_RANGE_ANYTIME_DISCOUNT : 0;

  return round(selectedVariant.price - (selectedVariant.price * (discount + timeRangeDiscount)) / 100, 2);
};
