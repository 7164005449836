import styled from '@emotion/styled';
import { CircularProgress } from '@mui/material';
import colors from 'styles/colors';
import { StepTitle } from 'UI';

export const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
  max-width: 600px;
  width: 100%;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

export const PaymentCardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const PaymentCards = styled.div`
  border-radius: 8px;
  box-shadow: 0px 4px 12px ${colors.blackSqueeze};
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 auto;
  max-width: 100%;
  overflow: hidden;
  width: 100%;

  & > #saved-credit-card:not(:nth-last-child(1)) {
    border-bottom: 1px solid ${colors.grey20};
  }
`;

export const AddNewCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const PaymentCardTitle = styled(StepTitle)`
  padding: 0;
`;

export const SummaryPaymentLoader = styled(CircularProgress)`
  margin: auto;
`;
