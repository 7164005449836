import { useContext } from 'react';
import useTranslation from 'shared/useTranslation';
import { Button, Footer } from 'UI';
import { WidgetContext } from 'Pages/Widget/Widget.constants';
import { WidgetActionType } from 'Pages/Widget/Widget.store';
import { useGetSelectedAppointment } from 'hooks/widget';
import { isMobile } from 'utils/responsive';
import { NextStepButton } from 'styles/Buttons';
import { AppointmentType } from 'shared/recurring';
import ArrowBack from '../../atoms/ArrowBack';

const VariantStepFooter = () => {
  const { state, dispatch } = useContext(WidgetContext);
  const { selectedVariantId } = state;

  const appointment = useGetSelectedAppointment();

  const handleGoNext = () => {
    if (appointment?.addons.length !== 0) {
      dispatch({ type: WidgetActionType.GO_TO_ADDONS });
      return;
    }
    // If there is no `addons`, then skip `ADDONS` section.
    dispatch({
      type: WidgetActionType.GO_TO_DATE_AND_TIME,
      payload: {
        isAdditionalStep: false,
      },
    });
  };
  const handleGoBack = () => {
    // Reset `appointment type` if we go back to `Select Appointment` page
    dispatch({
      type: WidgetActionType.UPDATE_APPOINTMENT_TYPE,
      payload: {
        appointmentType: AppointmentType.OneTime,
      },
    });
    dispatch({ type: WidgetActionType.PREVIOUS_STEP });
  };

  const showBackButton = !isMobile();

  const [translations] = useTranslation(['widget.step.back', 'widget.step.next'] as const);

  return (
    <Footer>
      {showBackButton ? (
        <Button color="secondary" onClick={handleGoBack}>
          <ArrowBack src={`${process.env.PUBLIC_URL}/icons/arrow-right-small.svg`} />
          {translations.widgetStepBack}
        </Button>
      ) : null}
      {selectedVariantId ? (
        <NextStepButton variant="contained" color="primary" onClick={handleGoNext}>
          {translations.widgetStepNext}
        </NextStepButton>
      ) : null}
    </Footer>
  );
};

export default VariantStepFooter;
