import * as Yup from 'yup';
import { setLocale } from 'yup';
import i18n from 'i18next';

setLocale({
  mixed: {
    required: () => i18n.t('common.error.field.required'),
  },
  string: {
    matches: () => i18n.t('common.error.field.invalid.format'),
    email: () => i18n.t('common.error.field.email.invalid'),
    max: ({ max }) => i18n.t('common.error.field.string.max', { max }),
  },
  number: {
    integer: () => i18n.t('common.error.field.invalid'),
    positive: () => i18n.t('common.error.field.number.positive'),
    min: ({ min }) => i18n.t('common.error.field.number.min', { min }),
    max: ({ max }) => i18n.t('common.error.field.number.max', { max }),
  },
  array: {
    min: () => i18n.t('common.error.field.required'),
  },
});

const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

export const ValidationRules = {
  string: Yup.string(),
  required: Yup.string().required(),
  requiredNum: Yup.number().nullable().required(),
  requiredPositiveNum: Yup.number().nullable().required().positive(),
  requiredPositiveIntegerNum: Yup.number().nullable().required().positive().integer(),
  requiredArray: Yup.array().min(1),
  email: Yup.string().email('').required(),
  phone: Yup.string().matches(phoneRegExp),
  emailNotRequired: Yup.string().email(),
  password: Yup.string().required(),
  file: Yup.mixed().required(),
};
