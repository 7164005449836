import { useContext, useMemo } from 'react';
import { Header, TopBar } from 'UI';
import Step from 'Domains/Step';
import StepHeader from 'Domains/StepHeader';
import { useTranslation } from 'react-i18next';
import formatTotalPrice from 'shared/formatTotalPrice';
import { useGetTotalPrice } from 'hooks/widget';
import { WidgetContext } from '../../Widget.constants';
import { WidgetStep } from '../../Widget.types';
import * as S from './WidgetContent.styles';

const WidgetContent = () => {
  const { state } = useContext(WidgetContext);
  const { t } = useTranslation();
  const price = useGetTotalPrice();
  const { currentStep, selectedAppointmentType, selectedRecurrenceType, widgetSteps } = state;
  const hideTopBar = [WidgetStep.PAYMENT_ERROR, WidgetStep.PAYMENT_SUCCESS].includes(currentStep);

  const renderPrice = useMemo(() => {
    const showPrice = price >= 0 && !hideTopBar;

    if (showPrice) {
      return (
        <S.SummaryValue>{formatTotalPrice(t, price, selectedRecurrenceType, selectedAppointmentType)}</S.SummaryValue>
      );
    }
    return null;
  }, [price, hideTopBar, t, selectedRecurrenceType, selectedAppointmentType]);

  return (
    <S.Container>
      {hideTopBar ? null : <Header>{renderPrice}</Header>}
      {hideTopBar ? null : <TopBar />}
      <S.BodyContainer>
        <StepHeader stepNumber={currentStep} />
        <Step stepNumber={currentStep} steps={widgetSteps} />
      </S.BodyContainer>
    </S.Container>
  );
};
export default WidgetContent;
