import { Button } from 'UI';
import styled from '@emotion/styled';

export const Header = styled.div`
  display: flex;
  padding: 24px 32px 8px 32px;

  @media (max-width: 768px) {
    padding: 16px;
  }
`;

export const CenterWrapper = styled.div`
  display: flex;
  margin-left: auto;
  justify-content: center;
`;

export const DateButton = styled(Button)`
  white-space: pre;
  padding: 0px 16px;
`;

export const StyledButton = styled(Button)`
  min-width: initial;
  @media (max-width: 768px) {
    min-width: 10px;
  }
`;
