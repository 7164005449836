import { useCallback } from 'react';
import toast from 'react-hot-toast';
import useTranslation from 'shared/useTranslation';
import { useLoginMutation } from 'store/auth/auth.api';
import { LoginFormFields } from './types';

export const useLoginAuth = () => {
  const [translations] = useTranslation(['login.form.sign.in.error'] as const);
  const [login, rest] = useLoginMutation();

  const loginSubmit = useCallback(
    async (values: LoginFormFields) => {
      try {
        await login(values).unwrap();
      } catch {
        toast.error(translations.loginFormSignInError);
      }
    },
    [login, translations.loginFormSignInError],
  );

  return [loginSubmit, rest] as const;
};
