import { WidgetContext } from 'Pages/Widget/Widget.constants';
import { useContext, useMemo } from 'react';
import { getTotalDiscount, getTotalPrice } from 'shared/formatTotalPrice';
import { selectAppointmentByAppointmentId } from 'store/appointment/selectors';
import { selectEnableAnytimeTimeRangeSelection, selectHasTimeRangeSelection } from 'store/availableTimeRange/selectors';
import { selectDiscounts } from 'store/discount/selectors';
import { selectIndustryByIndustryId } from '../store/industry/selectors';
import { useAppSelector } from './hooks';

export const useGetSelectedIndustry = () => {
  const { state } = useContext(WidgetContext);
  const { selectedIndustryId } = state;
  const industry = useAppSelector((state) => selectIndustryByIndustryId(state, selectedIndustryId));
  return industry;
};

export const useGetSelectedAppointment = () => {
  const { state } = useContext(WidgetContext);
  const { selectedAppointmentId } = state;
  const appointment = useAppSelector((state) => selectAppointmentByAppointmentId(state, selectedAppointmentId));
  return appointment;
};

export const useGetSelectedAddons = () => {
  const { state } = useContext(WidgetContext);
  const { selectedAppointmentId, selectedAddonIds } = state;
  const appointment = useAppSelector((state) => selectAppointmentByAppointmentId(state, selectedAppointmentId));

  return useMemo(() => {
    if (!appointment) {
      return [];
    }
    const { addons } = appointment;
    return addons.filter((addon) => selectedAddonIds.includes(addon.id));
  }, [appointment, selectedAddonIds]);
};

export const useGetSelectedVariant = () => {
  const { state } = useContext(WidgetContext);
  const { selectedAppointmentId, selectedVariantId } = state;
  const appointment = useAppSelector((state) => selectAppointmentByAppointmentId(state, selectedAppointmentId));

  return useMemo(() => {
    if (!appointment) {
      return undefined;
    }
    const { variants } = appointment;
    return variants.find((variant) => variant.id === selectedVariantId);
  }, [appointment, selectedVariantId]);
};

// refactor: return price and discount
export const useGetTotalPrice = () => {
  const { state } = useContext(WidgetContext);
  const { selectedAppointmentType, selectedRecurrenceType, selectedRequestedDate, selectedTimeRange } = state;
  const selectedVariant = useGetSelectedVariant();
  const selectedAddons = useGetSelectedAddons();
  const discounts = useAppSelector(selectDiscounts);
  const hasTimeRangeEnabled = useAppSelector(selectHasTimeRangeSelection);
  const enableAnytimeRange = useAppSelector(selectEnableAnytimeTimeRangeSelection);

  return useMemo(() => {
    const variantPrice = selectedVariant ? selectedVariant.price : 0;
    const addonsPrice = selectedAddons.reduce((prev, acc) => prev + acc.price, 0);

    const variantAndAddonsPrice = variantPrice + addonsPrice;

    const daysNumber = selectedRequestedDate ? 1 : 0;
    const price = daysNumber ? variantAndAddonsPrice * daysNumber : variantAndAddonsPrice;

    const totalDiscount = getTotalDiscount({
      appointmentType: selectedAppointmentType,
      discounts,
      enableAnytimeRange,
      hasTimeRangeEnabled,
      recurrenceType: selectedRecurrenceType,
      timeRange: selectedTimeRange,
    });
    const totalPrice = getTotalPrice(price, totalDiscount);

    return totalPrice;
  }, [
    discounts,
    enableAnytimeRange,
    hasTimeRangeEnabled,
    selectedAddons,
    selectedAppointmentType,
    selectedRecurrenceType,
    selectedRequestedDate,
    selectedTimeRange,
    selectedVariant,
  ]);
};
