import { createSlice } from '@reduxjs/toolkit';
import { widgetApi } from '../widget/widget.api';
import { DiscountReducerState } from './types';

const getInitialState = (): DiscountReducerState => ({
  discounts: [],
});

const discountSlice = createSlice({
  name: 'discountReducer',
  initialState: getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(widgetApi.endpoints.getWidgetDataByAppointmentGroups.matchFulfilled, (state, { payload }) => {
      state.discounts = payload.discounts;
    });
  },
});

const { reducer } = discountSlice;

export default reducer;
