import { Button, Footer } from 'UI';
import React, { useContext } from 'react';
import useTranslation from 'shared/useTranslation';
import { isMobile } from 'utils/responsive';
import { useGetIndustryId } from 'Pages/Widget/Widget.hooks';
import { WidgetContext } from 'Pages/Widget/Widget.constants';
import { WidgetActionType } from 'Pages/Widget/Widget.store';
import ArrowBack from '../../atoms/ArrowBack';

const AppointmentStepFooter = () => {
  const { dispatch } = useContext(WidgetContext);
  const industryId = useGetIndustryId();

  const handleGoBack = () => dispatch({ type: WidgetActionType.PREVIOUS_STEP });
  const showBackButton = !isMobile() && !industryId;

  const [translations] = useTranslation(['widget.step.back', 'widget.step.next'] as const);

  if (!showBackButton) {
    return null;
  }

  return (
    <Footer>
      <Button color="secondary" onClick={handleGoBack}>
        <ArrowBack src={`${process.env.PUBLIC_URL}/icons/arrow-right-small.svg`} />
        {translations.widgetStepBack}
      </Button>
    </Footer>
  );
};

export default AppointmentStepFooter;
