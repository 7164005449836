import { PageNoDataProps } from './PageNoData.types';
import * as S from './PageNoData.styles';

const PageNoData = ({ subtitle, title }: PageNoDataProps): JSX.Element => (
  <S.Wrapper>
    <S.Title>{title}</S.Title>
    {subtitle ? <S.Subtitle>{subtitle}</S.Subtitle> : null}
  </S.Wrapper>
);

export default PageNoData;
