import { formatExpireDate, isCreditCardExpired } from 'UI/Card/utils';
import { DialogActions } from '@mui/material';
import { ReactComponent as CrossIcon } from 'icons/cross.svg';
import useTranslation from 'shared/useTranslation';
import * as S from './styles';
import { CardDetailsPopupProps } from './types';

const CardDetailsPopup = ({ open, cardDetail, onClose, onRemoveCardClick }: CardDetailsPopupProps) => {
  const [translations, t] = useTranslation([
    'widget.step.summary.payment.card.details',
    'widget.step.summary.payment.card.is.outdated',
    'widget.step.summary.payment.card.number',
    'widget.step.summary.payment.card.remove',
    'widget.step.summary.payment.card.valid.thru',
    'credit.card.declined'
  ] as const);

  const { last4, exp_year, exp_month, isValid } = cardDetail;
  const expiredDate = formatExpireDate(exp_month, exp_year);
  const creditCardExpired = isCreditCardExpired(exp_month, exp_year);

  return (
    <S.DialogWrapper onClose={onClose} open={open} fullWidth>
      <S.DialogHeader>
        <S.Title>{translations.widgetStepSummaryPaymentCardDetails}</S.Title>
        <CrossIcon onClick={onClose} />
      </S.DialogHeader>
      <S.DialogContentWrapper>
        <S.ContentWrapper>
          <S.CardField>
            <S.CardFieldLabel>{translations.widgetStepSummaryPaymentCardNumber}</S.CardFieldLabel>
            <S.CardFieldValue>{t('common.field.card.number.placeholder', { last4 })}</S.CardFieldValue>
          </S.CardField>
          <S.ExpiredDateAndCvvWrapper>
            <S.CardField>
              <S.CardFieldLabel>{translations.widgetStepSummaryPaymentCardValidThru}</S.CardFieldLabel>
              <S.CardFieldValue>{expiredDate}</S.CardFieldValue>
            </S.CardField>
          </S.ExpiredDateAndCvvWrapper>
          {(creditCardExpired || !isValid) ? (
            <S.CardIsOutdatedText>{!isValid ? translations.creditCardDeclined : translations.widgetStepSummaryPaymentCardIsOutdated}</S.CardIsOutdatedText>
          ) : null}
        </S.ContentWrapper>
      </S.DialogContentWrapper>
      <DialogActions>
        <S.RemoveCardButton onClick={onRemoveCardClick}>
          {translations.widgetStepSummaryPaymentCardRemove}
        </S.RemoveCardButton>
      </DialogActions>
    </S.DialogWrapper>
  );
};

export default CardDetailsPopup;
