import { configureStore } from '@reduxjs/toolkit';
import { api } from './apiUtils/api';
import { logoutMiddleware } from './apiUtils/logoutMiddleware';
import appointmentReducer from './appointment/slice';
import authReducer from './auth/auth.slice';
import availableTimeRangeReducer from './availableTimeRange/slice';
import discountReducer from './discount/slice';
import industryReducer from './industry/slice';

export const store = configureStore({
  reducer: {
    appointmentReducer,
    auth: authReducer,
    availableTimeRangeReducer,
    discountReducer,
    industryReducer,
    [api.reducerPath]: api.reducer,
  },
  devTools: true,
  // eslint-disable-next-line unicorn/prefer-spread
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware, logoutMiddleware),
});
