import styled from '@emotion/styled';
import COLORS from 'styles/colors';

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  gap: 4px;
`;

export const LocationIconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Address = styled.p`
  color: ${COLORS.grey80};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  width: 100%;
  max-width: 228px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 290px) {
    max-width: 150px;
  }
`;
