import { useTranslation } from 'react-i18next';
import { displayDiscount } from 'shared/discounts';
import { getTimeRangeTranslated, TIME_RANGE_ANYTIME_DISCOUNT } from 'shared/timeRanges';
import IconSmallSelected from 'UI/IconSmallSelected';
import * as S from './styles';
import { TimeRangeProps } from './types';

const TimeRangeComponent = ({
  selected,
  showDiscount = false,
  time,
  timeRange,
  onSelect,
}: TimeRangeProps): JSX.Element => {
  const { t } = useTranslation();
  const onTimeChange = () => {
    onSelect(timeRange);
  };

  return (
    <S.Wrapper onClick={onTimeChange} showDiscount={showDiscount}>
      {showDiscount ? <S.Discount>{displayDiscount(TIME_RANGE_ANYTIME_DISCOUNT)}</S.Discount> : null}
      <IconSmallSelected selected={selected} />
      <S.Title>{getTimeRangeTranslated(timeRange, t)}</S.Title>
      {time ? <S.SubTitle isWithDiscount={showDiscount}>{time}</S.SubTitle> : null}
    </S.Wrapper>
  );
};

export default TimeRangeComponent;
