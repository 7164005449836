import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-shrink: 0;
  gap: 16px;
  justify-content: space-between;
  margin-top: auto;
  padding-bottom: 32px;
  padding-top: 16px;
`;
