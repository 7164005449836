import { ErrorWithMessage, StripeErrorMessage } from './types';

export function isServerErrorWithMessage(error: unknown): error is ErrorWithMessage<string> {
  return (
    typeof error === 'object' && error !== null && typeof (error as ErrorWithMessage<unknown>).data.message === 'string'
  );
}

export function isStripeErrorMessage(error: unknown): error is StripeErrorMessage {
  return (
    typeof error === 'object' && 'message' in (error as StripeErrorMessage) && 'stack' in (error as StripeErrorMessage)
  );
}
