import React, { useContext } from 'react';
import { isMobile } from 'utils/responsive';
import useTranslation from 'shared/useTranslation';
import { Button, Footer } from 'UI';
import { WidgetActionType } from 'Pages/Widget/Widget.store';
import { WidgetContext } from 'Pages/Widget/Widget.constants';
import { NextStepButton } from 'styles/Buttons';
import { useAppSelector } from 'hooks/hooks';
import { selectHasTimeRangeSelection } from 'store/availableTimeRange/selectors';
import { isAppointmentTypeRecurring } from 'shared/recurring';
import ArrowBack from '../../atoms/ArrowBack';

const DateAndTimeStepFooter = () => {
  const { state, dispatch } = useContext(WidgetContext);
  const {
    appointmentQuestions,
    selectedAppointmentType,
    selectedRequestedDate,
    selectedRequestedTime,
    selectedTimeRange,
  } = state;

  const hasTimeRangeEnabled = useAppSelector(selectHasTimeRangeSelection);

  const handleGoNext = () => {
    // Note: Skip `ADDITIONAL_INFORMATION` step, when `questions` is empty array.
    if (appointmentQuestions.length === 0) {
      dispatch({
        type: WidgetActionType.GO_TO_SUMMARY,
        payload: {
          isAdditionalStep: false,
        },
      });
      return;
    }
    dispatch({ type: WidgetActionType.NEXT_STEP });
  };
  const handleGoBack = () => dispatch({ type: WidgetActionType.PREVIOUS_STEP });

  const showBackButton = !isMobile();

  const showNextButton =
    (hasTimeRangeEnabled && selectedRequestedDate && selectedTimeRange) ||
    (isAppointmentTypeRecurring(selectedAppointmentType) && selectedRequestedTime) ||
    (selectedRequestedDate && selectedRequestedTime);

  const [translations] = useTranslation(['widget.step.back', 'widget.step.next'] as const);

  return (
    <Footer>
      {showBackButton ? (
        <Button color="secondary" onClick={handleGoBack}>
          <ArrowBack src={`${process.env.PUBLIC_URL}/icons/arrow-right-small.svg`} />
          {translations.widgetStepBack}
        </Button>
      ) : null}
      {showNextButton ? (
        <NextStepButton onClick={handleGoNext} variant="contained" color="primary">
          {translations.widgetStepNext}
        </NextStepButton>
      ) : null}
    </Footer>
  );
};

export default DateAndTimeStepFooter;
