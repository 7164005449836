import styled from '@emotion/styled';
import { Typography } from '@mui/material';

export const Container = styled(Typography)`
  font-size: 12px;
  max-width: 400px;
  line-height: 15px;
  font-weight: 400;

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 20px;
    margin-left: 0px;
    margin-top: 0px;
  }
`;
