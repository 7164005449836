import { createSlice } from '@reduxjs/toolkit';
import { widgetApi } from '../widget/widget.api';
import { IndustryReducerState } from './types';

const getInitialState = (): IndustryReducerState => ({
  industries: [],
});

const industrySlice = createSlice({
  name: 'industryReducer',
  initialState: getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(widgetApi.endpoints.getWidgetDataByAppointmentGroups.matchFulfilled, (state, { payload }) => {
      state.industries = payload.industries;
    });
  },
});

const { reducer } = industrySlice;

export default reducer;
