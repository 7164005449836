import React from 'react';
import { WidgetStep } from 'Pages/Widget/Widget.types';
import AdditionalInformationStep from 'Domains/AdditionalInformationStep';
import AppointmentStep from 'Domains/AppointmentStep';
import DateAndTimeStep from 'Domains/DateAndTimeStep';
import IndustryStep from 'Domains/IndustryStep';
import SummaryStep from 'Domains/SummaryStep';
import VariantStep from 'Domains/VariantStep';
import AddonStep from 'Domains/AddonStep';
import StripeMiddleware from 'UI/StripeMiddleware/StripeMiddleware';
import { StepProps } from './types';
import * as S from './styles';
import PaymentSuccessStep from '../PaymentSuccessStep';
import PaymentErrorStep from '../PaymentErrorStep';
import AppointmentStepFooter from './molecules/AppointmentStepFooter';
import VariantStepFooter from './molecules/VariantStepFooter';
import AdditionalInformationFooter from './molecules/AdditionalInformationFooter';
import AddonStepFooter from './molecules/AddonStepFooter';
import DateAndTimeStepFooter from './molecules/DateAndTimeStepFooter';

const Step = ({ steps, stepNumber }: StepProps): JSX.Element => {
  const renderStepByNumber = () => {
    switch (stepNumber) {
      case WidgetStep.INDUSTRY:
        return <IndustryStep />;
      case WidgetStep.APPOINTMENT:
        return <AppointmentStep />;
      case WidgetStep.VARIANT:
        return <VariantStep />;
      case WidgetStep.ADDON:
        return <AddonStep />;
      case WidgetStep.DATE_AND_TIME:
        return <DateAndTimeStep />;
      case WidgetStep.ADDITIONAL_INFORMATION:
        return <AdditionalInformationStep />;
      case WidgetStep.SUMMARY:
        return (
          <StripeMiddleware>
            <SummaryStep steps={steps} />
          </StripeMiddleware>
        );
      case WidgetStep.PAYMENT_SUCCESS:
        return <PaymentSuccessStep />;
      case WidgetStep.PAYMENT_ERROR:
        return <PaymentErrorStep />;
      default:
        break;
    }
  };

  const renderFooterByNumber = () => {
    switch (stepNumber) {
      case WidgetStep.APPOINTMENT:
        return <AppointmentStepFooter />;
      case WidgetStep.VARIANT:
        return <VariantStepFooter />;
      case WidgetStep.ADDON:
        return <AddonStepFooter />;
      case WidgetStep.ADDITIONAL_INFORMATION:
        return <AdditionalInformationFooter />;
      case WidgetStep.DATE_AND_TIME:
        return <DateAndTimeStepFooter />;
      default:
        return null;
    }
  };

  return (
    <S.StepContainer>
      {renderStepByNumber()}
      {renderFooterByNumber()}
    </S.StepContainer>
  );
};

export default Step;
