import styled from '@emotion/styled';
import { Button } from 'UI';
import colors from 'styles/colors';

export const Container = styled(Button)`
  box-shadow: 1px 1px 6px 0 ${colors.cornflowerBlue};
  width: 240px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;
