import styled from '@emotion/styled';
import { StepContainer } from 'UI';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 12px;
`;

export const Container = styled(StepContainer)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex: 1;
  gap: 32px;
  padding: 0;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 16px;
  }
`;
