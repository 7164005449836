import { Fragment, useContext, useEffect, useState } from 'react';
import useTranslation from 'shared/useTranslation';
import { WidgetContext } from 'Pages/Widget/Widget.constants';
import { useDurationLabel } from 'shared/useDurationLabel/useDurationLabel.hook';
import { getFormattedDate } from 'shared/getters/getFormattedDate';
import { RESOLUTIONS } from 'utils/responsive';
import { displayPriceFormatted } from 'shared/string';
import { getTimeRangeTranslated } from 'shared/timeRanges';
import { displayDiscount } from 'shared/discounts';
import { useAppSelector } from 'hooks/hooks';
import { selectEnableAnytimeTimeRangeSelection, selectHasTimeRangeSelection } from 'store/availableTimeRange/selectors';
import { useMediaQuery } from 'shared/useMediaQuery';
import formatTotalPrice, { getTotalDiscount } from 'shared/formatTotalPrice';
import {
  useGetSelectedAddons,
  useGetSelectedAppointment,
  useGetSelectedIndustry,
  useGetSelectedVariant,
  useGetTotalPrice,
} from 'hooks/widget';
import { selectDiscounts } from 'store/discount/selectors';
import * as S from './styles';

const SummaryData = (): JSX.Element => {
  const { state } = useContext(WidgetContext);
  const {
    appointmentQuestions,
    selectedAppointmentType,
    selectedRecurrenceType,
    selectedRequestedDate,
    selectedTimeRange,
  } = state;

  const selectedAddons = useGetSelectedAddons();
  const selectedVariant = useGetSelectedVariant();
  const selectedAppointment = useGetSelectedAppointment();
  const selectedIndustry = useGetSelectedIndustry();

  const discounts = useAppSelector(selectDiscounts);
  const hasTimeRangeEnabled = useAppSelector(selectHasTimeRangeSelection);
  const enableAnytimeRange = useAppSelector(selectEnableAnytimeTimeRangeSelection);

  const isMobile = useMediaQuery(RESOLUTIONS.MOBILE_RESOLUTION);
  const [expandOrderDetails, setExpandOrderDetails] = useState(!isMobile);
  const [expandBill, setExpandBill] = useState(!isMobile);

  const [translations, t] = useTranslation([
    'common.label.duration',
    'common.label.empty',
    'common.label.requested.date',
    'common.label.requested.time',
    'common.label.requested.timerange',
    'widget.step.summary.information',
    'widget.step.summary.summarydata.additionalinformation',
    'widget.step.summary.summarydata.addons',
    'widget.step.summary.summarydata.appointment',
    'widget.step.summary.summarydata.bill',
    'widget.step.summary.summarydata.category',
    'widget.step.summary.summarydata.discount',
    'widget.step.summary.summarydata.havepets',
    'widget.step.summary.summarydata.industry',
    'widget.step.summary.summarydata.living',
    'widget.step.summary.summarydata.note',
    'widget.step.summary.summarydata.orderdetails',
    'widget.step.summary.summarydata.specialtreatment',
    'widget.step.summary.summarydata.summary',
    'widget.step.summary.summarydata.variant',
    'widget.step.summary.authorization.hold.notice',
  ] as const);

  const durationLabel = useDurationLabel(selectedVariant, selectedAddons);
  const discount = getTotalDiscount({
    appointmentType: selectedAppointmentType,
    discounts,
    enableAnytimeRange,
    hasTimeRangeEnabled,
    recurrenceType: selectedRecurrenceType,
    timeRange: selectedTimeRange,
  });

  const price = useGetTotalPrice();

  useEffect(() => {
    setExpandOrderDetails(!isMobile);
    setExpandBill(!isMobile);
  }, [isMobile]);

  const renderSectionDate = () => {
    if (hasTimeRangeEnabled) {
      const { selectedTimeRange } = state;
      return (
        <S.OrderDetail>
          <S.OrderDetailLabel>{translations.commonLabelRequestedTimerange}</S.OrderDetailLabel>
          <S.OrderDetailValue>{getTimeRangeTranslated(selectedTimeRange, t)}</S.OrderDetailValue>
        </S.OrderDetail>
      );
    }

    const { selectedRequestedTime } = state;
    return (
      <S.OrderDetail>
        <S.OrderDetailLabel>{translations.commonLabelRequestedTime}</S.OrderDetailLabel>
        <S.OrderDetailValue>{selectedRequestedTime?.time}</S.OrderDetailValue>
      </S.OrderDetail>
    );
  };

  return (
    <S.Wrapper>
      <S.OrderDetails
        expanded={expandOrderDetails}
        title={translations.widgetStepSummarySummarydataOrderdetails}
        onChange={() => setExpandOrderDetails((prevExpand) => !prevExpand)}
      >
        <S.OrderDetailsList withUpperBorder>
          <S.OrderDetail>
            <S.OrderDetailLabel>{translations.commonLabelRequestedDate}</S.OrderDetailLabel>
            <S.OrderDetailValue>
              {selectedRequestedDate
                ? getFormattedDate(selectedRequestedDate, 'MMMM dd')
                : translations.commonLabelEmpty}
            </S.OrderDetailValue>
          </S.OrderDetail>
          {renderSectionDate()}
          <S.OrderDetail>
            <S.OrderDetailLabel>{translations.commonLabelDuration}</S.OrderDetailLabel>
            <S.OrderDetailValue>{durationLabel}</S.OrderDetailValue>
          </S.OrderDetail>
          {selectedIndustry && (
            <S.OrderDetail>
              <S.OrderDetailLabel>{translations.widgetStepSummarySummarydataIndustry}</S.OrderDetailLabel>
              <S.OrderDetailValue>{selectedIndustry.name}</S.OrderDetailValue>
            </S.OrderDetail>
          )}
          <S.OrderDetail>
            <S.OrderDetailLabel>{translations.widgetStepSummarySummarydataAppointment}</S.OrderDetailLabel>
            {selectedAppointment && <S.OrderDetailValue>{selectedAppointment.displayName}</S.OrderDetailValue>}
          </S.OrderDetail>
          {selectedVariant ? (
            <S.OrderDetail>
              <S.OrderDetailLabel>{translations.widgetStepSummarySummarydataVariant}</S.OrderDetailLabel>
              <S.OrderDetailValue>{selectedVariant.name}</S.OrderDetailValue>
            </S.OrderDetail>
          ) : null}
        </S.OrderDetailsList>
        {selectedAddons.length > 0 ? (
          <>
            <S.Title>{translations.widgetStepSummarySummarydataAddons}</S.Title>
            <S.OrderDetailsList>
              {selectedAddons.map((selectedAddon, index) => (
                <S.OrderDetailLabel key={`${selectedAddon.id}${index}`}>{selectedAddon.name}</S.OrderDetailLabel>
              ))}
            </S.OrderDetailsList>
          </>
        ) : null}
        {appointmentQuestions.length > 0 ? (
          <>
            <S.Title>{translations.widgetStepSummarySummarydataAdditionalinformation}</S.Title>
            <S.OrderDetailsList>
              {appointmentQuestions.map((appointmentQuestion) => (
                <Fragment key={appointmentQuestion.id}>
                  <S.OrderDetailValue>{appointmentQuestion.question}</S.OrderDetailValue>
                  <S.OrderDetailLabel>{appointmentQuestion.answer}</S.OrderDetailLabel>
                </Fragment>
              ))}
            </S.OrderDetailsList>
          </>
        ) : null}
      </S.OrderDetails>
      <S.BillBlock
        expanded={expandBill}
        title={translations.widgetStepSummarySummarydataBill}
        onChange={() => setExpandBill((prevExpand) => !prevExpand)}
      >
        <S.OrderDetailsList withUpperBorder>
          {selectedVariant && (
            <S.OrderDetail>
              <S.OrderDetailLabel>{selectedVariant.name}</S.OrderDetailLabel>
              <S.OrderDetailValue>{displayPriceFormatted(selectedVariant.price)}</S.OrderDetailValue>
            </S.OrderDetail>
          )}
          {selectedAddons.map((selectedAddon, index) => (
            <S.OrderDetail key={`${selectedAddon.id}${index}`}>
              <S.OrderDetailLabel>{selectedAddon.name}</S.OrderDetailLabel>
              <S.OrderDetailValue>+$ {selectedAddon.price}</S.OrderDetailValue>
            </S.OrderDetail>
          ))}
        </S.OrderDetailsList>
        {discount ? (
          <S.OrderDetail>
            <S.OrderDetailLabel>{translations.widgetStepSummarySummarydataDiscount}</S.OrderDetailLabel>
            <S.OrderDetailValue>{displayDiscount(discount)}</S.OrderDetailValue>
          </S.OrderDetail>
        ) : null}
        <S.OrderDetail>
          <S.OrderDetailLabel>{translations.widgetStepSummarySummarydataSummary}</S.OrderDetailLabel>
          <S.OrderDetailValue>
            {formatTotalPrice(t, price, selectedRecurrenceType, selectedAppointmentType)}
          </S.OrderDetailValue>
        </S.OrderDetail>
      </S.BillBlock>
      {price ? <S.TextNote>{translations.widgetStepSummaryAuthorizationHoldNotice}</S.TextNote> : null}
      <S.SummaryInformation>{translations.widgetStepSummaryInformation}</S.SummaryInformation>
    </S.Wrapper>
  );
};

export default SummaryData;
