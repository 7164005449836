import React, { useContext } from 'react';
import { Button, Footer } from 'UI';
import useTranslation from 'shared/useTranslation';
import { isMobile } from 'utils/responsive';
import { WidgetActionType } from 'Pages/Widget/Widget.store';
import { NextStepButton } from 'styles/Buttons';
import { isStringEmptyTrimmed } from 'shared/string';
import { isAdditionalInformationNotValid } from 'Domains/AdditionalInformationStep/validation';
import { WidgetContext } from 'Pages/Widget/Widget.constants';
import ArrowBack from '../../atoms/ArrowBack';

const AdditionalInformationFooter = () => {
  const { state, dispatch } = useContext(WidgetContext);
  const { appointmentQuestions } = state;
  const [translations] = useTranslation(['widget.step.back', 'widget.step.next', 'widget.step.summary'] as const);

  const handleGoNext = () => dispatch({ type: WidgetActionType.NEXT_STEP });
  const handleGoBack = () => dispatch({ type: WidgetActionType.PREVIOUS_STEP });

  const showBackButton = !isMobile();
  const showNextButton = appointmentQuestions.every(
    (appointmentQuestion) => !isStringEmptyTrimmed(appointmentQuestion.answer),
  );

  const disabled = appointmentQuestions.some((appointmentQuestion) =>
    isAdditionalInformationNotValid(appointmentQuestion.answer),
  );

  return (
    <Footer>
      {showBackButton ? (
        <Button color="secondary" onClick={handleGoBack}>
          <ArrowBack src={`${process.env.PUBLIC_URL}/icons/arrow-right-small.svg`} />
          {translations.widgetStepBack}
        </Button>
      ) : null}
      {showNextButton ? (
        <NextStepButton disabled={disabled} color="primary" variant="contained" onClick={handleGoNext}>
          {translations.widgetStepSummary}
        </NextStepButton>
      ) : null}
    </Footer>
  );
};

export default AdditionalInformationFooter;
