import * as endpoints from '../apiUtils/endpoints';
import { api } from '../apiUtils/api';
import { CreditCardInfo } from './client.types';

export const clientApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getClientCreditCardInfo: builder.query<CreditCardInfo[], void>({
      query: () => `${endpoints.client}/creditcard`,
      providesTags: ['Client'],
    }),
    removeClientCreditCard: builder.mutation<unknown, string>({
      query: (creditCardId) => ({
        url: `${endpoints.client}/creditcard/${creditCardId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Client'],
    }),
    saveClientCreditCard: builder.mutation<unknown, string>({
      query: (creditCardId) => ({
        url: `${endpoints.client}/creditcard`,
        method: 'POST',
        body: { token: creditCardId },
      }),
      invalidatesTags: ['Client'],
    }),
  }),
});

export const { useGetClientCreditCardInfoQuery, useRemoveClientCreditCardMutation, useSaveClientCreditCardMutation } =
  clientApi;
