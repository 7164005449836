import React, { useContext } from 'react';
import { WidgetActionType } from 'Pages/Widget/Widget.store';
import { WidgetContext } from 'Pages/Widget/Widget.constants';
import SelectTextBlock from 'UI/SelectTextBlock';
import useTranslation from 'shared/useTranslation';
import PageNoData from 'UI/PageNoData';
import { useGetSelectedAppointment } from 'hooks/widget';
import { orderBy } from 'lodash';
import * as S from './styles';
import RecurringPicker from './molecules/RecurringPicker';

const VariantStep = () => {
  const { state, dispatch } = useContext(WidgetContext);
  const { selectedVariantId } = state;

  const appointment = useGetSelectedAppointment();
  const variants = orderBy(appointment?.variants, [(variant) => variant.name.toLowerCase()]);

  const [translations] = useTranslation([
    'widget.step.variants.empty.title',
    'widget.step.variants.failed.title',
  ] as const);

  const onVariantClick = (variantId: number) => {
    dispatch({ type: WidgetActionType.UPDATE_VARIANT_STEP, payload: { variantId } });
  };

  if (!variants) {
    return <PageNoData title={translations.widgetStepVariantsFailedTitle} />;
  }

  if (variants.length === 0) {
    return <PageNoData title={translations.widgetStepVariantsEmptyTitle} />;
  }

  return (
    <S.VariantWrapper>
      <S.VariantContainer>
        {variants.map((variant) => (
          <SelectTextBlock
            id={variant.id}
            isSelected={variant.id === selectedVariantId}
            key={variant.id}
            name={variant.name}
            price={variant.price}
            onClick={onVariantClick}
          />
        ))}
      </S.VariantContainer>
      {appointment?.allowRecurrence ? <RecurringPicker /> : null}
    </S.VariantWrapper>
  );
};

export default VariantStep;
