const COLORS = {
  athensGray: '#EEF2F4',
  cornflowerBlue: '#427df1',
  cornflowerBlueOpacity10: 'rgba(66,125,241, 0.1)',
  cornflowerBlueOpacity40: 'rgba(66,125,241, 0.4)',
  skyBlue: '#6DC7EF',
  blackSqueeze: '#EBF1F7',
  paleSky: '#71757C',
  bombay: '#B5B9C2',
  catskillWhite: '#F9FBFC',
  white: '#fff',
  grey20: '#E9EAEC',
  grey50: '#F9FAFB',
  grey60: '#afb4ba',
  grey80: '#595D62',
  grey300: '#D1D5DB',
  grey500: '#6B7280',
  grey900: '#111928',
  athensGray2: '#E9EDF0',
  mercury: '#e6e6e6',
  dustyGray: '#999999',
  grey10: '#f7f8fa',
  iron: '#CDD0D4',
  black: '#000',
  grey70: '#8a8a8a',
  gray900: '#111928',
  error100: '#d32f2f',
  error200: '#FF0C18',
  error300: '#F05252',
  primary600: '#1C64F2',
  transparent: 'transparent',
};

export default COLORS;
