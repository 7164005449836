import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

export function useStripePaymentId() {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async () => {
    const cardEl = elements?.getElement(CardElement);

    if (!stripe || !elements || !cardEl) {
      throw new Error('there is no stripe');
    }

    const createTokenResponse = await stripe.createToken(cardEl);

    const { error: createTokenError, token } = createTokenResponse;

    if (createTokenError || !token) {
      throw new Error(createTokenError?.message || 'something bad with payment');
    }

    return token.id;
  };

  return {
    handleSubmit,
  };
}
