export enum AppointmentType {
  Recurring = 'RECURRING',
  OneTime = 'ONE_TIME',
}

export function isAppointmentTypeRecurring(appointmentType?: AppointmentType) {
  return appointmentType === AppointmentType.Recurring;
}

export function isAppointmentTypeOneTime(appointmentType?: AppointmentType) {
  return appointmentType === AppointmentType.OneTime;
}
