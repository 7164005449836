import styled from '@emotion/styled';
import { Button } from '../UI';

export const NextStepButton = styled(Button)`
  width: 100%;

  @media (min-width: 768px) {
    max-width: 246px;
    margin-left: auto;
  }
`;
