import { useReducer } from 'react';
import { CircularProgress } from '@mui/material';
import { widgetReducer } from './Widget.store';
import { initialState, WidgetContext } from './Widget.constants';
import { useGetWidgetData } from './Widget.hooks';
import WidgetContent from './components/WidgetContent';

const Widget = () => {
  const [state, dispatch] = useReducer(widgetReducer, initialState);

  const { isLoading } = useGetWidgetData(dispatch);

  if (isLoading) {
    return <CircularProgress size="5rem" />;
  }

  return (
    <WidgetContext.Provider value={{ state, dispatch }}>
      <WidgetContent />
    </WidgetContext.Provider>
  );
};

export default Widget;
