import styled from '@emotion/styled';
import { TextField } from '@mui/material';
import COLORS from 'styles/colors';

export const Input = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: 'none',
    },
  },
});

export const InputStyles = {
  color: COLORS.grey60,
  backgroundColor: COLORS.blackSqueeze,
  borderRadius: '5px',
};
