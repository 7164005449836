import { useTranslation } from 'react-i18next';
import { displayDiscount } from 'shared/discounts';
import { getRecurrenceTypeTranslated } from 'shared/recurrance';
import IconSmallSelected from 'UI/IconSmallSelected';
import * as S from './Recurrence.styles';
import { RecurrenceProps } from './Recurrences.types';

const RecurrenceComponent = ({ discount, isSelected, recurrenceType, onSelect }: RecurrenceProps) => {
  const { t } = useTranslation();
  const onRecurrenceChange = () => {
    onSelect(recurrenceType);
  };

  return (
    <S.Wrapper onClick={onRecurrenceChange}>
      <IconSmallSelected selected={isSelected} />
      <S.Title>{getRecurrenceTypeTranslated(t, recurrenceType)}</S.Title>
      {discount ? <S.Discount>{displayDiscount(discount)}</S.Discount> : null}
    </S.Wrapper>
  );
};

export default RecurrenceComponent;
