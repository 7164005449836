import styled from '@emotion/styled';
import colors from 'styles/colors';
import { Typography } from '@mui/material';

export const Wrapper = styled.div<{ showDiscount: boolean }>`
  background-color: ${colors.white};
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  flex: 0 0 232px;
  gap: 8px;
  height: 100%;
  justify-content: center;
  height: 70px;
  max-width: 232px;
  padding: 12px 44px 12px 24px;
  position: relative;
  width: 100%;

  &:hover {
    box-shadow: 0px 4px 12px ${colors.blackSqueeze};
  }

  @media (max-width: 768px) {
    max-width: calc(50% - 8px);
    padding: ${({ showDiscount }) => (showDiscount ? '8px 40px 8px 16px;' : '8px 24px 8px 16px;')};
  }
`;

export const Title = styled(Typography)`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  flex-shrink: 0;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 1.2;
  margin: 2px 0;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SubTitle = styled.span<{ isWithDiscount: boolean }>`
  flex: 1;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
  margin: ${({ isWithDiscount }) => (isWithDiscount ? '0px 24px 0px 0px' : '0px')};
`;

export const Discount = styled.div`
  background: ${colors.grey10};
  color: ${colors.cornflowerBlue};
  height: 24px;
  border-radius: 4px;
  position: absolute;
  right: 24px;
  text-align: center;
  top: 24px;
  width: 30px;
`;
