import React, { useContext } from 'react';
import { WidgetActionType } from 'Pages/Widget/Widget.store';
import SelectTextBlock from 'UI/SelectTextBlock';
import PageNoData from 'UI/PageNoData';
import useTranslation from 'shared/useTranslation';
import { useGetSelectedAppointment } from 'hooks/widget';
import { WidgetContext } from 'Pages/Widget/Widget.constants';
import { orderBy } from 'lodash';
import * as S from './styles';

const AddonStep = () => {
  const { state, dispatch } = useContext(WidgetContext);
  const { selectedAddonIds } = state;
  const [translations] = useTranslation([
    'widget.step.addons.failed',
    'widget.step.addons.empty.title',
    'widget.step.addons.empty.subtitle',
  ] as const);

  const appointment = useGetSelectedAppointment();
  const addons = orderBy(appointment?.addons, [(addon) => addon.name.toLowerCase()]);

  const onAddonClick = (addonId: number) =>
    dispatch({
      type: WidgetActionType.UPDATE_ADDON_STEP,
      payload: { addonId },
    });

  if (!addons) {
    return <PageNoData title={translations.widgetStepAddonsFailed} />;
  }

  if (addons.length === 0) {
    return (
      <PageNoData
        subtitle={translations.widgetStepAddonsEmptySubtitle}
        title={translations.widgetStepAddonsEmptyTitle}
      />
    );
  }

  return (
    <S.AddonStepContainer>
      {addons?.map((addon, index) => (
        <SelectTextBlock
          id={addon.id}
          isSelected={selectedAddonIds.includes(addon.id)}
          key={addon.id + index}
          name={addon.name}
          price={addon.price}
          onClick={onAddonClick}
        />
      ))}
    </S.AddonStepContainer>
  );
};

export default AddonStep;
