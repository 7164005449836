import React, { useMemo } from 'react';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import enUS from 'date-fns/locale/en-US';
import UserService from 'services/user.service';
import CustomToolbar from '../CustomToolbar';
import CustomCalendarDay from '../CustomCalendarDay';
import { DatePickerProps } from './DatePicker.types';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './styles.css';

const locales = {
  'en-US': enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const DatePicker = ({ withResultDays, onDayClick }: DatePickerProps) => {
  const { views, formats } = useMemo(
    () => ({
      views: { month: true },
      formats: {
        weekdayFormat: (date: Date): string => localizer.format(date, 'EEE'),
      },
    }),
    [],
  );

  const user = UserService.getLocalUser();

  return (
    <Calendar
      formats={formats}
      localizer={localizer}
      views={views}
      events={[]}
      components={{
        toolbar: CustomToolbar,
        month: {
          dateHeader: (props) => (
            <CustomCalendarDay
              {...props}
              withResultDays={withResultDays}
              onDayClickCallback={onDayClick}
              buildingTimezone={user?.buildingTimezone || user?.building?.timezone}
            />
          ),
        },
      }}
      startAccessor="start"
      endAccessor="end"
    />
  );
};

export default DatePicker;
