import styled from '@emotion/styled';
import colors from 'styles/colors';

export const PaymentCardsWrapper = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 auto;
  max-width: 100%;
  overflow: hidden;
  width: 100%;

  & > #saved-credit-card:not(:nth-last-child(2)) {
    border-bottom: 1px solid ${colors.grey20};
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 50%;
  width: 100%;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;
