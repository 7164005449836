import * as React from 'react';
import Box from '@mui/material/Box';
import { BorderLinearProgress } from './ProgressBar.styles';
import { CustomizedProgressBarsProps } from './ProgressBar.types';

const ProgressBar = ({ currentStep }: CustomizedProgressBarsProps): JSX.Element => (
  <Box>
    <BorderLinearProgress variant="determinate" value={currentStep} />
  </Box>
);

export default ProgressBar;
