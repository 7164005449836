import { TFunction } from 'react-i18next';
import { DEFAULT_HOUR, Time } from 'UI/TimePicker/constants';
import { AppointmentType, isAppointmentTypeOneTime, isAppointmentTypeRecurring } from 'shared/recurring';
import { useGetTotalPrice } from 'hooks/widget';
import { generateDateWithTimezone } from 'utils';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'hooks/hooks';
import UserService from 'services/user.service';
import { selectHasTimeRangeSelection } from 'store/availableTimeRange/selectors';
import { WidgetStep } from './Widget.types';
import { WidgetState } from './Widget.store';

export const normalise = (value: number, state: WidgetState) => ((value - 0) * 100) / (state.widgetSteps.length - 0);
export const userBrowserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

function getRequestedDate(
  hasTimeRangeSelection: boolean | undefined,
  requestedDate: Date | undefined,
  requestedTime: Time | undefined | null,
  buildingTimezone: string,
) {
  if (hasTimeRangeSelection && requestedDate && buildingTimezone) {
    return generateDateWithTimezone(requestedDate, DEFAULT_HOUR, buildingTimezone);
  }
  if (requestedDate && requestedTime) {
    return generateDateWithTimezone(requestedDate, requestedTime.timeForDate, buildingTimezone);
  }
  return undefined;
}

export const useGetBookingPayload = (state: WidgetState) => {
  const { appointmentGroupId } = useParams<{ appointmentGroupId: string }>();
  const hasTimeRangeEnabled = useAppSelector(selectHasTimeRangeSelection);
  const user = UserService.getLocalUser();

  const {
    appointmentQuestions,
    selectedAddonIds,
    selectedAppointmentType,
    selectedRecurrenceType,
    selectedRequestedDate,
    selectedRequestedTime,
    selectedTimeRange,
    selectedVariantId,
  } = state;

  const price = useGetTotalPrice();

  const requestedDateFormatted = getRequestedDate(
    hasTimeRangeEnabled,
    selectedRequestedDate,
    selectedRequestedTime,
    user?.buildingTimezone || user?.building?.timezone,
  );
  if (requestedDateFormatted) {
    return {
      additionalInformation: appointmentQuestions,
      addonIds: selectedAddonIds,
      appointmentGroupId: appointmentGroupId ? Number(appointmentGroupId) : undefined,
      createdAtTimezone: userBrowserTimezone,
      price,
      // Note: `one-time` this is how `backend` accepts it.
      recurrenceType: isAppointmentTypeOneTime(selectedAppointmentType) ? 'one-time' : selectedRecurrenceType,
      requestedDate: requestedDateFormatted,
      timeRange: selectedTimeRange,
      variantId: selectedVariantId,
    };
  }

  return {};
};

const WIDGET_STEP_TITLE_MAP = {
  [WidgetStep.ADDITIONAL_INFORMATION]: 'widget.step.title.additionalinformation',
  [WidgetStep.ADDON]: 'widget.step.title.addons',
  [WidgetStep.APPOINTMENT]: 'widget.step.title.appointment',
  [WidgetStep.DATE_AND_TIME]: 'widget.step.title.dateandtime',
  [WidgetStep.INDUSTRY]: 'widget.step.title.industry',
  [WidgetStep.SUMMARY]: 'widget.step.title.summary',
  [WidgetStep.VARIANT]: 'widget.step.title.variant',
  [WidgetStep.PAYMENT_SUCCESS]: undefined,
  [WidgetStep.PAYMENT_ERROR]: undefined,
};

export function getWidgetStepTitleTranslated(
  t: TFunction<'translation'>,
  type: WidgetStep,
  params?: { appointmentType?: AppointmentType },
) {
  // Note: On `DATE_AND_TIME` step possible 2 titles depending on `appointmentType`.
  if (type === WidgetStep.DATE_AND_TIME && params?.appointmentType) {
    const key = isAppointmentTypeRecurring(params.appointmentType)
      ? 'widget.step.title.dateandtime.recurring'
      : 'widget.step.title.dateandtime.onetime';
    return t(key);
  }
  const key = WIDGET_STEP_TITLE_MAP[type];
  return t(key ?? 'common.label.empty');
}
