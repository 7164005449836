import { TFunction } from 'react-i18next';
import round from 'lodash/round';
import { Discount } from 'store/discount/types';
import { TimeRanges } from 'store/availableTimeRange/types';
import { RecurrenceType } from '../Pages/Widget/Widget.types';
import getPriceForRepeatType from './getPriceForRepeatType';
import { getDiscountByRecurrenceType } from './recurrance';
import { displayPriceFormatted } from './string';
import { TIME_RANGE_ANYTIME_DISCOUNT } from './timeRanges';
import { AppointmentType, isAppointmentTypeRecurring } from './recurring';

export function getTotalPrice(price: number, discount = 0) {
  const totalPrice = price - (price * discount) / 100;
  return round(totalPrice, 2);
}

export function getTotalDiscount({
  appointmentType,
  discounts = [],
  hasTimeRangeEnabled,
  recurrenceType,
  timeRange,
  enableAnytimeRange,
}: {
  appointmentType: AppointmentType | undefined;
  discounts: Discount[];
  hasTimeRangeEnabled: boolean;
  recurrenceType: RecurrenceType | undefined;
  timeRange: TimeRanges | undefined;
  enableAnytimeRange: boolean;
}) {
  const recurrenceDiscount = isAppointmentTypeRecurring(appointmentType)
    ? getDiscountByRecurrenceType(discounts, recurrenceType)
    : 0;

  const isTimeRangeAnytimeSelected = timeRange === TimeRanges.Anytime && enableAnytimeRange;
  const timeRangeDiscount = hasTimeRangeEnabled && isTimeRangeAnytimeSelected ? TIME_RANGE_ANYTIME_DISCOUNT : 0;
  return recurrenceDiscount + timeRangeDiscount;
}

export default function formatTotalPrice(
  t: TFunction<'translation'>,
  totalPrice: number,
  repeatMode?: RecurrenceType,
  appointmentType?: AppointmentType,
) {
  const recurringTypeTranslated = getPriceForRepeatType(t, repeatMode, appointmentType);

  if (recurringTypeTranslated) {
    return `${displayPriceFormatted(totalPrice)} / ${recurringTypeTranslated}`;
  }

  return displayPriceFormatted(totalPrice);
}
