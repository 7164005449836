import styled from '@emotion/styled';
import colors from 'styles/colors';

export const Wrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  width: 100%;
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  font-weight: 500;
  width: 100%;
  background-color: ${colors.white};
  border-radius: 8px;
  box-shadow: 0px 4px 12px ${colors.blackSqueeze};
  cursor: pointer;
  gap: 16px;
  padding: 12px 21px;

  @media (max-width: 768px) {
    width: 100%;
    background-color: transparent;
    box-shadow: none;
    padding: 0;
  }
`;

export const StripePaymentForm = styled.form`
  width: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
`;

export const CardInput = styled.div`
  padding: 8px 16px;
  border: 1px solid ${colors.black};
  border-radius: 4px;
  width: 100%;
  background-color: ${colors.grey10};
`;

export const CardStyles = {
  base: {
    fontSize: '16px',
  },
};

export const Selected = styled.div<{ isSelected?: boolean }>`
  display: ${({ isSelected }) => (isSelected ? 'flex' : 'none')};
  position: absolute;
  width: 20px;
  height: 20px;
  border-bottom-left-radius: 8px;
  border-top-right-radius: 8px;
  right: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  background-color: ${colors.cornflowerBlue};
`;
