import styled from '@emotion/styled';
import { css } from '@emotion/react';
import COLORS from 'styles/colors';

interface SelectedProps {
  isSelected?: boolean;
}

interface MonthDayProps {
  isRecurringDate?: boolean;
  isSelected?: boolean;
  withResultDays?: boolean;
}

interface DayWrapperProps {
  isPast?: boolean;
}

export const MonthDay = styled.div<MonthDayProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2px;
  padding-left: 5px;
  width: 100%;
  padding-bottom: auto;
  height: 100%;
  ${({ isRecurringDate }) =>
    isRecurringDate
      ? css`
          background: ${COLORS.cornflowerBlueOpacity40};
          color: ${COLORS.grey80};
        `
      : null};
  ${({ isSelected, withResultDays }) =>
    isSelected &&
    css`
      background: ${COLORS.cornflowerBlue};
      color: ${COLORS.white};
      height: calc(100% - 4px);
      border-radius: 8px;
      padding-top: 0px;
      padding-left: 2px;
      ${withResultDays
        ? css`
            border: 1px solid ${COLORS.cornflowerBlue};
            background: ${COLORS.cornflowerBlue};
            color: ${COLORS.white};
          `
        : null}
    `}
`;

export const DayWrapper = styled.div<DayWrapperProps>`
  position: relative;
  margin: 0px;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ isPast }) =>
    isPast
      ? css`
          width: 100%;
          cursor: not-allowed;
          background: ${COLORS.grey10};
          color: ${COLORS.grey60};
        `
      : null};
`;

export const Selected = styled.div<SelectedProps>`
  display: ${({ isSelected }) => (isSelected ? 'flex' : 'none')};
  position: absolute;
  width: 20px;
  height: 18px;
  border-bottom-left-radius: 8px;
  border-top-right-radius: 8px;
  right: 0;
  padding-right: 4px;
  top: 0;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS.cornflowerBlue};
`;

export const SelectedIcon = styled.img`
  width: 12px;
`;
