import format from 'date-fns/format';
import { formatInTimeZone } from 'date-fns-tz';
import { WidgetActionType } from 'Pages/Widget/Widget.store';
import { useContext, useMemo } from 'react';
import { RecurrenceType } from 'Pages/Widget/Widget.types';
import { compareDates } from 'shared/compareDates';
import { getTime, startOfDay, parseISO } from 'date-fns';
import { getDifferenceInWeeksFromRequestedToCurrentDate } from 'shared/getDifferenceInWeeksFromRequestedToCurrentDate';
import { isAppointmentTypeOneTime } from 'shared/recurring';
import { WidgetContext } from 'Pages/Widget/Widget.constants';
import { CustomCalendarDayProps } from './CustomCalendarDay.types';
import * as S from './CustomCalendarDay.styles';

const CustomCalendarDay = ({ date, withResultDays, onDayClickCallback, buildingTimezone }: CustomCalendarDayProps) => {
  const { dispatch, state } = useContext(WidgetContext);
  const { selectedAppointmentType, selectedRecurrenceType, selectedRequestedDate } = state;

  const parsedDate = new Date(date);
  const day = format(parsedDate, 'd');

  const currentCalendarDayWithTimezone = parseISO(
    formatInTimeZone(new Date(), buildingTimezone, 'yyyy-MM-dd HH:mm:ss'),
  );
  const isSelected = selectedRequestedDate ? compareDates(selectedRequestedDate, parsedDate) === 0 : false;
  const isPast = getTime(startOfDay(currentCalendarDayWithTimezone)) <= getTime(date);

  const handlePickDay = () => {
    if (isPast && dispatch) {
      dispatch({
        type: WidgetActionType.UPDATE_REQUESTED_DATE,
        payload: { requestedDate: parsedDate },
      });
      if (onDayClickCallback) {
        onDayClickCallback();
      }
    }
  };

  const isRecurringDate = useMemo(() => {
    if (!selectedRequestedDate) {
      return false;
    }

    if (isAppointmentTypeOneTime(selectedAppointmentType)) {
      return false;
    }

    const currentDate = new Date(date);
    const { isCurrentDayMatchesNextRecurringWeekStartDay, diffInWeeks } =
      getDifferenceInWeeksFromRequestedToCurrentDate(currentDate, selectedRequestedDate);

    const isCurrentDateAfterSelectedDay = compareDates(selectedRequestedDate, currentDate) === 1;

    const isDateEvery2Weeks =
      diffInWeeks % 2 === 0 && isCurrentDayMatchesNextRecurringWeekStartDay && isCurrentDateAfterSelectedDay;

    const isDateEvery3Weeks =
      diffInWeeks >= 3 &&
      diffInWeeks % 3 === 0 &&
      isCurrentDayMatchesNextRecurringWeekStartDay &&
      isCurrentDateAfterSelectedDay;

    const isDateEvery4Weeks =
      diffInWeeks >= 4 &&
      diffInWeeks % 4 === 0 &&
      isCurrentDayMatchesNextRecurringWeekStartDay &&
      isCurrentDateAfterSelectedDay;

    return {
      [RecurrenceType.Weekly]: isCurrentDayMatchesNextRecurringWeekStartDay && isCurrentDateAfterSelectedDay,
      [RecurrenceType.Every2Weeks]: isDateEvery2Weeks,
      [RecurrenceType.Every3Weeks]: isDateEvery3Weeks,
      [RecurrenceType.Every4Weeks]: isDateEvery4Weeks,
      [RecurrenceType.Daily]: false,
    }[selectedRecurrenceType ?? RecurrenceType.Daily];
  }, [selectedRequestedDate, selectedAppointmentType, date, selectedRecurrenceType]);

  return (
    <S.DayWrapper isPast={!isPast} onClick={handlePickDay}>
      <S.MonthDay withResultDays={withResultDays} isSelected={isSelected} isRecurringDate={isRecurringDate}>
        {day}
      </S.MonthDay>
    </S.DayWrapper>
  );
};

export default CustomCalendarDay;
