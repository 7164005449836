import React from 'react';
import * as S from './PaymentStatusWrapper.styles';

interface PaymentStatusProps {
  icon: string;
  title: string;
  subtitle: string;
  children: React.ReactNode;
}

const PaymentStatusWrapper = ({ children, icon, subtitle, title }: PaymentStatusProps): JSX.Element => (
  <S.Wrapper>
    <S.IconWrapper>
      <S.Icon src={icon} />
    </S.IconWrapper>
    <S.WrapperText>
      <S.PaymentStepTitle>{title}</S.PaymentStepTitle>
      <S.PaymentStepSubtitle>{subtitle}</S.PaymentStepSubtitle>
    </S.WrapperText>
    {children}
  </S.Wrapper>
);

export default PaymentStatusWrapper;
