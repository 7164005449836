import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/types';

export const selectUser = createSelector(
  (state: RootState) => state.auth.user,
  (user) => user,
);

export const selectAccessToken = createSelector(
  (state: RootState) => state.auth.accessToken,
  (accessToken) => accessToken,
);
