import { ReactComponent as CrossIcon } from 'icons/cross.svg';
import useTranslation from 'shared/useTranslation';
import * as S from './styles';
import { RemoveCardConfirmationProps } from './types';

const RemoveCardConfirmationPopup = ({ open, isLoading, onRemoveCard, onClose }: RemoveCardConfirmationProps) => {
  const [translations] = useTranslation([
    'widget.step.summary.payment.card.remove.confirmation',
    'widget.step.summary.payment.card.remove',
    'common.btn.back',
  ] as const);

  return (
    <S.DialogWrapper onClose={onClose} open={open} fullWidth>
      <S.DialogHeader>
        <S.Title>{translations.widgetStepSummaryPaymentCardRemove}</S.Title>
        <CrossIcon onClick={onClose} />
      </S.DialogHeader>
      <S.DialogContentWrapper>{translations.widgetStepSummaryPaymentCardRemoveConfirmation}</S.DialogContentWrapper>
      <S.DialogActionsWrapper>
        <S.RemoveCardButton loading={isLoading} onClick={onRemoveCard}>
          {translations.widgetStepSummaryPaymentCardRemove}
        </S.RemoveCardButton>
        <S.BackButton onClick={onClose}>{translations.commonBtnBack}</S.BackButton>
      </S.DialogActionsWrapper>
    </S.DialogWrapper>
  );
};

export default RemoveCardConfirmationPopup;
