import { ReactNode } from 'react';

export enum RESOLUTIONS {
  TABLET_RESOLUTION = 1280,
  FRAME_RESOLUTION = 960,
  MOBILE_RESOLUTION = 768,
}

function isTablet() {
  return window.innerWidth < RESOLUTIONS.TABLET_RESOLUTION;
}

function isIframe() {
  return window.innerWidth <= RESOLUTIONS.FRAME_RESOLUTION && window.innerWidth >= RESOLUTIONS.MOBILE_RESOLUTION;
}

function isMobile() {
  return window.innerWidth < RESOLUTIONS.MOBILE_RESOLUTION;
}

export const MobileView = ({ children }: { children: ReactNode }) => (isMobile() ? <>{children}</> : null);

export const IframeView = ({ children }: { children: ReactNode }) => (isIframe() ? <>{children}</> : null);

export const DesktopView = ({ children }: { children: ReactNode }) => (!isMobile() ? <>{children}</> : null);

export { isTablet, isMobile, isIframe };
