import { useContext } from 'react';
import { WidgetActionType } from 'Pages/Widget/Widget.store';
import PageNoData from 'UI/PageNoData';
import { selectAppointmentsByIndustryId } from 'store/appointment/selectors';
import { useAppSelector } from 'hooks/hooks';
import useTranslation from 'shared/useTranslation';
import { WidgetContext } from 'Pages/Widget/Widget.constants';
import * as S from './styles';
import AppointmentComponent from './molecules/AppointmentComponent';
import { getAppointmentsFormatted } from './utils';

const AppointmentStep = () => {
  const { state, dispatch } = useContext(WidgetContext);
  const { selectedIndustryId, selectedAppointmentId } = state;

  const appointments = useAppSelector((state) => selectAppointmentsByIndustryId(state, selectedIndustryId));

  const [translations] = useTranslation([
    'widget.step.price.pre',
    'widget.step.appointment.empty.title',
    'widget.step.appointment.empty.subtitle',
    'widget.step.appointment.failed.title',
  ] as const);

  const onAppointmentClick = (appointmentId: number) => {
    const appointment = appointments.find((appointmentToFind) => appointmentToFind.id === appointmentId);
    const appointmentQuestions = appointment
      ? appointment.bookingInformation.map(({ message, id }) => ({
          answer: '',
          id,
          question: message,
        }))
      : [];

    dispatch({
      type: WidgetActionType.UPDATE_APPOINTMENT_STEP,
      payload: {
        appointmentId,
        appointmentQuestions,
      },
    });
  };

  if (!appointments) {
    return <PageNoData title={translations.widgetStepAppointmentFailedTitle} />;
  }

  if (appointments.length === 0) {
    return <PageNoData title={translations.widgetStepAppointmentEmptyTitle} />;
  }

  return (
    <S.Wrapper>
      {getAppointmentsFormatted(appointments).map((appointment) => (
        <AppointmentComponent
          description={appointment.description}
          id={appointment.id}
          isSelected={appointment.id === selectedAppointmentId}
          key={appointment.id}
          name={appointment.name}
          price={appointment.asLowPrice}
          priceText={translations.widgetStepPricePre}
          svgUrl={appointment.svgUrl}
          onClick={onAppointmentClick}
        />
      ))}
    </S.Wrapper>
  );
};

export default AppointmentStep;
