import { useRef, useState } from 'react';

const MAX_SLIDE_DISTANCE = 100;
const MIN_SLIDE_DISTANCE = 0;

const MAX_SLIDE_T0_HIDE = 20;

export const useTouchSlide = (onClose: () => void) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [startTouchPosition, setStartTouchPosition] = useState<number>(0);
  const [distanceSlide, setDistanceSlide] = useState<number>(0);

  const handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
    const touchPosition = event.touches[0];
    setStartTouchPosition(touchPosition.clientY);
  };

  const handleTouchMove = (event: React.TouchEvent<HTMLDivElement>) => {
    if (!ref.current || startTouchPosition === 0) return;

    const touchPosition = event.touches[0];
    const currentY = touchPosition.clientY;
    const dialogHeight = ref?.current.clientHeight;

    if (currentY >= dialogHeight) return;

    const distance = currentY - startTouchPosition;
    const normalizedValue = (distance / dialogHeight) * 100;

    if (distance > 0) {
      requestAnimationFrame(() => {
        setDistanceSlide(Math.floor(normalizedValue));
      });
    }
  };

  const handleTouchEnd = () => {
    if (distanceSlide === null) return;
    if (distanceSlide > MAX_SLIDE_T0_HIDE) {
      setDistanceSlide(MAX_SLIDE_DISTANCE);
      setTimeout(() => {
        onClose();
      }, 300);
      setTimeout(() => {
        setDistanceSlide(MIN_SLIDE_DISTANCE);
      }, 500);
      return;
    }
    setDistanceSlide(MIN_SLIDE_DISTANCE);
  };

  return {
    ref,
    distanceSlide,
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
  };
};
