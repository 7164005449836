import { isToday } from 'date-fns';

export const isHourSlotNotAvailable = (requestedDate: Date | undefined, hour: number, todayHour: number) => {
  if (!requestedDate) {
    return true;
  }

  if (isToday(requestedDate)) {
    return hour <= todayHour;
  }

  return false;
};
