import { CardType } from '../store/client/client.types';

const PAYMENT_CARD_URL_MAP = {
  [CardType.Visa]: 'visa.svg',
  [CardType.AmericanExpress]: 'american-express.svg',
  [CardType.Amex]: 'american-express.svg',
  [CardType.DinersClub]: 'diners-club.svg',
  [CardType.Discover]: 'discover.svg',
  [CardType.JCB]: 'JCB.svg',
  [CardType.MasterCard]: 'master-card-icon.svg',
  [CardType.Unionpay]: 'unionpay.svg',
  [CardType.Unknown]: 'unknown-card.svg',
};

export const getBrandIcon = (brand: CardType) => PAYMENT_CARD_URL_MAP[brand] ?? PAYMENT_CARD_URL_MAP[CardType.Unknown];
