import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/types';

export const selectIndustries = createSelector(
  (state: RootState) => state.industryReducer.industries,
  (state: RootState) => state.appointmentReducer.appointments,
  (industries, appointments) =>
    industries.map((industry) => ({
      ...industry,
      appointments: appointments.filter((appointment) => industry.appointmentIds.includes(appointment.id)),
    })),
);

export const selectIndustryByIndustryId = createSelector(
  (state: RootState) => state.industryReducer.industries,
  (_: RootState, industryId: number | undefined) => industryId,
  (industries, industryId) => industries.find((industry) => industry.id === industryId),
);
