import { useState } from 'react';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';
import toast from 'react-hot-toast';
import { useSaveClientCreditCardMutation } from 'store/client/client.api';
import { useStripePaymentId } from 'shared/useStripePaymentId';
import useTranslation from 'shared/useTranslation';
import { isServerErrorWithMessage, isStripeErrorMessage } from 'shared/ErrorTypeCheck';

export const useAddNewCard = (onClose: () => void) => {
  const [translations] = useTranslation(['toast.success.title', 'toast.failure.title'] as const);

  const [isCardValid, setIsCardValid] = useState(false);
  const [isStripeTokenLoading, setIsStripeTokenLoading] = useState(false);
  const { handleSubmit } = useStripePaymentId();

  const [saveClientCreditCard, { isLoading }] = useSaveClientCreditCardMutation();

  const onCardChange = (e: StripeCardElementChangeEvent) => {
    setIsCardValid(e.complete);
  };

  const onSaveAddCreditCard = async () => {
    setIsStripeTokenLoading(true);
    const creditCardId = await handleSubmit();
    setIsStripeTokenLoading(false);

    try {
      await saveClientCreditCard(creditCardId).unwrap();
      toast.success(translations.toastSuccessTitle);
      onClose();
    } catch (error) {
      if (isStripeErrorMessage(error)) {
        toast.error(error.message);
        return;
      }

      if (isServerErrorWithMessage(error)) {
        toast.error(error.data.message);
        return;
      }
      toast.error(translations.toastFailureTitle);
    }
  };

  return { isCardValid, isStripeTokenLoading, onCardChange, onSaveAddCreditCard, isLoading };
};
