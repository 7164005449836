import styled from '@emotion/styled';
import colors from 'styles/colors';
import { Accordion } from 'UI';
import { Typography } from '@mui/material';

interface OrderDetailsListProps {
  withUpperBorder?: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 0 auto;
  max-width: 400px;
  overflow: auto;
  position: relative;
  width: 100%;

  @media (max-width: 768px) {
    max-width: 100%;
    overflow: visible;
    position: static;
  }
`;

export const TextNote = styled.div`
  background: ${colors.cornflowerBlueOpacity10};
  border-radius: 8px;
  color: ${colors.cornflowerBlue};
  font-size: 12px;
  font-weight: 500;
  padding: 16px;

  @media (max-width: 768px) {
    display: none;
  }
`

export const SummaryInformation = styled(Typography)`
  color: ${colors.grey60};
  font-size: 14px;
  margin-top: auto;
  text-align: center;
  white-space: break-spaces;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const OrderDetails = styled(Accordion)`
  border-radius: 4px;
  box-shadow: none;

  &:hover {
    box-shadow: 0px 4px 12px ${colors.blackSqueeze};
  }
`;

export const Title = styled.p`
  color: ${colors.grey80};
  font-weight: 500;
  font-size: 18px;
  padding: 8px 0px;
  margin: 10px 0px;
  line-height: 23px;
`;

export const OrderDetailsList = styled.div<OrderDetailsListProps>`
  display: flex;
  flex-direction: column;
  ${({ withUpperBorder }) => withUpperBorder && `border-top: 1px solid ${colors.grey80};`}
  border-bottom: 1px solid ${colors.grey80};
`;

export const OrderDetail = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 4px;
`;

export const OrderDetailLabel = styled.span`
  color: ${colors.grey80};
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  padding: 8px 0px;
`;

export const OrderDetailValue = styled.span`
  color: ${colors.grey80};
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  min-width: 10%;
  overflow: hidden;
  padding: 8px 0px;
  text-overflow: ellipsis;
  white-space: pre-wrap;
`;

export const BillBlock = styled(Accordion)`
  border-radius: 4px;
  box-shadow: none;
  &:hover {
    box-shadow: 0px 4px 12px ${colors.blackSqueeze};
  }
`;
