import { useAppSelector } from 'hooks/hooks';
import { useRef } from 'react';
import { selectAvailableTimeRanges, selectHasTimeRangeSelection } from 'store/availableTimeRange/selectors';
import { DatePicker, StepContainer, TimePicker } from 'UI';
import TimeRanges from './molecules/TimeRanges';

const DateAndTimeStep = (): JSX.Element => {
  const hasTimeRangeEnabled = useAppSelector(selectHasTimeRangeSelection);
  const availableTimeRanges = useAppSelector(selectAvailableTimeRanges);

  const ref = useRef<HTMLDivElement | null>(null);

  const onDayClick = () => {
    ref?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const isTimeRangesAvailable = hasTimeRangeEnabled && availableTimeRanges.length > 0;

  return (
    <StepContainer>
      <DatePicker onDayClick={onDayClick} />
      {isTimeRangesAvailable ? (
        <div ref={ref}>
          <TimeRanges />
        </div>
      ) : (
        <div ref={ref}>
          <TimePicker />
        </div>
      )}
    </StepContainer>
  );
};

export default DateAndTimeStep;
