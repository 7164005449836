export interface Time {
  time: string;
  id: number;
  timeForDate: number;
}

export type TimeList = Time[];

export const TIME_SLOTS_LIST: TimeList[] = [
  [
    { time: '6:00 AM', id: 0, timeForDate: 6 },
    { time: '7:00 AM', id: 1, timeForDate: 7 },
    { time: '8:00 AM', id: 2, timeForDate: 8 },
    { time: '9:00 AM', id: 3, timeForDate: 9 },
  ],
  [
    { time: '10:00 AM', id: 4, timeForDate: 10 },
    { time: '11:00 AM', id: 5, timeForDate: 11 },
    { time: '12:00 PM', id: 6, timeForDate: 12 },
    { time: '1:00 PM', id: 7, timeForDate: 13 },
  ],
  [
    { time: '2:00 PM', id: 8, timeForDate: 14 },
    { time: '3:00 PM', id: 9, timeForDate: 15 },
    { time: '4:00 PM', id: 10, timeForDate: 16 },
    { time: '5:00 PM', id: 11, timeForDate: 17 },
  ],
  [
    { time: '6:00 PM', id: 12, timeForDate: 18 },
    { time: '7:00 PM', id: 13, timeForDate: 19 },
    { time: '8:00 PM', id: 14, timeForDate: 20 },
    { time: '9:00 PM', id: 15, timeForDate: 21 },
  ],
  [
    { time: '10:00 PM', id: 16, timeForDate: 22 },
    { time: '11:00 PM', id: 17, timeForDate: 23 },
    { time: '12:00 AM', id: 18, timeForDate: 0 },
    { time: '1:00 AM', id: 19, timeForDate: 1 },
  ],
  [
    { time: '2:00 AM', id: 20, timeForDate: 2 },
    { time: '3:00 AM', id: 21, timeForDate: 3 },
    { time: '4:00 AM', id: 22, timeForDate: 4 },
    { time: '5:00 AM', id: 23, timeForDate: 5 },
  ],
];

export const DEFAULT_HOUR = 6;
