import * as Yup from 'yup';
import { generateTestIdByDomain } from 'shared/generateTestIdByDomain';
import { ValidationRules } from 'shared/validationRules';

const generateTestId = generateTestIdByDomain('login-form');

export const PLACEHOLDER_EMAIL = 'example@mail.com';
export const PLACEHOLDER_PASSWORD = '"•••••••••••••"';

export const TEST_IDS = {
  emailInput: generateTestId.input('email'),
  passwordInput: generateTestId.input('password'),
  signinButton: generateTestId.button('sign-in'),
  signupButton: generateTestId.button('sign-up'),
};

export const loginValidationSchema = Yup.object({
  email: ValidationRules.email,
  password: ValidationRules.password.max(250),
});

export const APPOINTMENT_GROUPS_PATH_BY_DEFAULT = '/65';
