import { isAfter, startOfMonth } from 'date-fns';
import { getBrandIcon } from '../../shared/getBrandIcon';
import { CreditCardInfo } from '../../store/client/client.types';

export const formatExpireDate = (expMonth: number, expYear: number) => {
  const formatExpMonth = expMonth < 10 ? `0${expMonth}` : expMonth;

  return `${formatExpMonth}/${expYear}`;
};

export const isCreditCardExpired = (expMonth: number, expYear: number) => {
  const currentDate = new Date();

  const cardExpirationDate = startOfMonth(new Date(expYear, expMonth));

  return isAfter(currentDate, cardExpirationDate);
};

export const getFormattedCardDetail = (cardData: CreditCardInfo) => {
  const { brand, exp_year, exp_month, isDefault, isValid } = cardData;

  const icon = getBrandIcon(brand);
  const expiredDate = formatExpireDate(exp_month, exp_year);
  const creditCardExpired = isCreditCardExpired(exp_month, exp_year);

  return { icon, expiredDate, creditCardExpired, isDefault, isValid };
};
