import { Action, Middleware } from '@reduxjs/toolkit';
import TokenService from 'services/token.service';
import UserService from 'services/user.service';
import { logout } from '../auth/auth.actions';

/* eslint-disable  @typescript-eslint/no-explicit-any */
const next = (_next: (arg0: any) => any) => (_action: Action<unknown>) => {
  if (logout.match(_action)) {
    TokenService.deleteLocalRefreshToken();
    TokenService.deleteLocalAccessToken();
    UserService.deleteLocalUser();
  }
  return _next(_action);
};

export const logoutMiddleware: Middleware<{}, unknown> = () => next;
