import styled from '@emotion/styled';
import colors from 'styles/colors';

export const Wrapper = styled.div`
  background-color: ${colors.white};
  border-radius: 8px;
  box-shadow: 0 4px 14px 0 rgba(233, 237, 240, 0.3);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
  width: 100%;
`;

export const AppointmentInformationWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  gap: 16px;
  padding: 16px;

  @media (max-width: 768px) {
    padding: 12px;
  }
`;

export const ImageWrapper = styled.div`
  height: 147px;
  width: 142px;
  flex-shrink: 0;

  @media (max-width: 768px) {
    height: 74px;
    width: 74px;
  }
`;

export const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

export const InformationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (max-width: 768px) {
    gap: 8px;
  }
`;

export const Title = styled.h3`
  color: ${colors.gray900};
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  display: -webkit-box;
  max-width: 100px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

export const Description = styled.p`
  color: ${colors.grey500};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;

  display: -webkit-box;
  max-width: 200px;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const AppointmentFooterWrapper = styled.div`
  align-items: center;
  background-color: ${colors.blackSqueeze};
  display: flex;
  justify-content: space-between;
  overflow: hidden;
`;

export const AsLowAsWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const AsLowAsText = styled.div`
  color: ${colors.cornflowerBlue};
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  padding: 12px 16px;

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 8px 12px;
  }
`;

export const AsLowAsPrice = styled.span`
  font-size: 20px;
  font-weight: 500;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const ShowMoreButton = styled.button`
  border: none;
  color: ${colors.cornflowerBlue};
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  height: 100%;
  margin-left: auto;
  padding: 12px 16px;
  text-decoration-line: underline;

  &:hover {
    opacity: 0.8;
  }

  @media (max-width: 768px) {
    padding: 8px 12px;
  }
`;

export const ShowMoreInformationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 622px;
`;

export const ShowMoreImageWrapper = styled.div`
  height: 74px;
  margin-bottom: 14px;
  width: 74px;

  @media (max-width: 500px) {
    align-self: center;
  }
`;

export const ShowMoreImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

export const ShowMoreTitle = styled.h3`
  color: ${colors.gray900};
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0 0 8px;
`;

export const ShowMoreDescription = styled.p`
  color: ${colors.grey500};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
`;
