import styled from '@emotion/styled';
import colors from 'styles/colors';
import { StepSubtitle, StepTitle } from '../index';

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  max-width: 462px;
  padding: 0 16px;
  margin: 0 auto;
  width: 100%;
`;

export const IconWrapper = styled.div`
  height: 100%;
  margin-bottom: 84px;
  max-height: 150px;
  max-width: 150px;
  width: 100%;

  @media (max-width: 768px) {
    margin-bottom: 42px;
  }
`;

export const Icon = styled.img`
  height: 100%;
  width: 100%;
`;

export const PaymentStepSubtitle = styled(StepSubtitle)`
  align-items: center;
  color: ${colors.grey70};
  display: flex;
  font-size: 14px;
  line-height: 22px;
  padding: 0;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

export const PaymentStepTitle = styled(StepTitle)`
  padding: 0;
`;

export const WrapperText = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  text-align: center;
  white-space: break-spaces;
  width: 100%;

  @media (max-width: 768px) {
    gap: 8px;
  }
`;
