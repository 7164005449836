import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import COLORS from 'styles/colors';

export const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 4,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: COLORS.athensGray,
  },
  [`& .${linearProgressClasses.bar}`]: {
    background: `linear-gradient(270deg, ${COLORS.cornflowerBlue} 0.02%, ${COLORS.skyBlue} 120.76%)`,
  },
}));
