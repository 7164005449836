import styled from '@emotion/styled';

const INDUSTRY_BLOCK_WIDTH = 168;

export const Wrapper = styled.div`
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(auto-fill, ${INDUSTRY_BLOCK_WIDTH}px);
  max-width: 100%;
  min-width: 0;
  padding: 0 16px;

  @media (max-width: 768px) {
    gap: 12px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    padding: 0px;
  }
`;
