import { DialogContent } from '@mui/material';
import * as S from './styles';
import { useTouchSlide } from './hooks';
import { ShowMorePopupProps } from './types';

const ShowMorePopup = ({ isOpen, children, onClose }: ShowMorePopupProps) => {
  const { ref, distanceSlide, handleTouchStart, handleTouchMove, handleTouchEnd } = useTouchSlide(onClose);

  return (
    <S.DialogWrapper onClose={onClose} open={isOpen}>
      <S.DialogContentWrapper
        ref={ref}
        moveDown={distanceSlide}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <S.Header>
          <div onClick={onClose}>
            <S.Close />
          </div>
          <div>
            <S.HomeIndicatorIcon />
          </div>
        </S.Header>
        <DialogContent>{children}</DialogContent>
      </S.DialogContentWrapper>
    </S.DialogWrapper>
  );
};

export default ShowMorePopup;
