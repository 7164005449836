import { ReactElement } from 'react';
import { resolveValue, ToastBar, Toaster } from 'react-hot-toast';
import useTranslation from 'shared/useTranslation';
import Toast from './Toast';
import { getToastTitle, toastStyles } from './Toast.utils';

const ToasterProvider = ({ children }: { children: ReactElement }): ReactElement => {
  const [, translate] = useTranslation([]);
  return (
    <>
      <Toaster>
        {(toast) => (
          <ToastBar toast={toast} style={toastStyles}>
            {({ icon }) => (
              <Toast
                title={getToastTitle(toast.type, translate)}
                icon={icon}
                subtitle={resolveValue(toast.message, toast)}
              />
            )}
          </ToastBar>
        )}
      </Toaster>
      {children}
    </>
  );
};

export default ToasterProvider;
