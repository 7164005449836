export enum TimeRanges {
  Anytime = 'anytime',
  EarlyMorning = 'early-morning',
  Morning = 'morning',
  Midday = 'midday',
  Afternoon = 'afternoon',
  Evening = 'evening',
}

export type TimeRange = {
  endTimeMin: number;
  key: TimeRanges;
  startTimeMin: number;
};

export type AvailableTimeRangeReducerState = {
  availableTimeRanges: TimeRange[];
  enableAnytimeTimeRange: boolean;
  hasTimeRangeSelection: boolean;
};
