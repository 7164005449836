import React, { useContext, useEffect } from 'react';
import { WidgetActionType } from 'Pages/Widget/Widget.store';
import { WidgetContext } from 'Pages/Widget/Widget.constants';
import COLORS from 'styles/colors';
import UserService from 'services/user.service';
import { getHours } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { TIME_SLOTS_LIST, TimeList } from './constants';
import * as S from './TimePicker.styles';
import { isHourSlotNotAvailable } from './utils';

const TimePicker = () => {
  const { dispatch, state } = useContext(WidgetContext);
  const { selectedRequestedDate, selectedRequestedTime } = state;

  const user = UserService.getLocalUser();

  const todayHours = getHours(utcToZonedTime(new Date(), user?.buildingTimezone));

  const handleSelectTime = (
    isDisabledSelection: boolean,
    { time, id, timeForDate }: { time: string; id: number; timeForDate: number },
  ) => {
    if (isDisabledSelection) {
      return;
    }
    dispatch({
      type: WidgetActionType.UPDATE_REQUESTED_TIME,
      payload: { requestedTime: { time, id, timeForDate } },
    });
  };

  useEffect(() => {
    if (!selectedRequestedTime) return;

    const isSelectedDateInPast = isHourSlotNotAvailable(
      selectedRequestedDate,
      selectedRequestedTime.timeForDate,
      todayHours,
    );

    if (isSelectedDateInPast) {
      dispatch({
        type: WidgetActionType.UPDATE_REQUESTED_TIME,
        payload: { requestedTime: null },
      });
    }
  }, [dispatch, selectedRequestedDate, selectedRequestedTime, todayHours, user.buildingTimezone]);

  const renderTimesList = (timeList: TimeList) =>
    timeList.map(({ time, id, timeForDate }) => {
      const isSlotDisabled = isHourSlotNotAvailable(selectedRequestedDate, timeForDate, todayHours);
      const isSelected = id === selectedRequestedTime?.id;

      return (
        <S.TimeItem
          disabled={isSlotDisabled}
          isSelected={isSelected}
          key={id + time}
          onClick={() => handleSelectTime(isSlotDisabled, { time, id, timeForDate })}
        >
          <S.Selected isSelected={isSelected}>
            <S.SelectedIcon src={`${process.env.PUBLIC_URL}/icons/tick-small.svg`} />
          </S.Selected>
          {time}
        </S.TimeItem>
      );
    });

  return (
    <S.TimeCarousel
      height={63}
      navButtonsProps={{
        style: {
          color: COLORS.black,
          backgroundColor: COLORS.white,
        },
      }}
      indicators={false}
      navButtonsAlwaysVisible={true}
      animation="slide"
      autoPlay={false}
    >
      {TIME_SLOTS_LIST.map((timeList, id) => (
        <S.TimeList key={id}>{renderTimesList(timeList)}</S.TimeList>
      ))}
    </S.TimeCarousel>
  );
};

export default TimePicker;
