import styled from '@emotion/styled';
import colors from 'styles/colors';
import { Button } from 'UI';
import { Dialog, DialogContent } from '@mui/material';

export const DialogWrapper = styled(Dialog)``;

export const DialogHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 16px 12px 12px;
`;

export const Title = styled.div`
  font-family: Outfit, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const DialogContentWrapper = styled(DialogContent)`
  padding: 12px 16px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const CardField = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
`;

export const CardFieldLabel = styled.div`
  color: ${colors.grey900};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const CardFieldValue = styled.div`
  color: ${colors.grey500};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const ExpiredDateAndCvvWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const CardIsOutdatedText = styled.div`
  color: ${colors.error200};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const RemoveCardButton = styled(Button)`
  color: ${colors.grey80};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  width: 100%;
`;
