import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  @media (max-width: 768px) {
    margin-bottom: 0px;
    padding: 8px 0;
  }
`;
