import { useSelector } from 'react-redux';
import { selectUser } from 'store/auth/auth.selectors';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Location } from 'icons/location.svg';
import * as S from './HeaderAddressMobile.styles';

const HeaderAddressMobile = () => {
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const { buildingName, buildingAddress } = user || {};

  if (!buildingAddress && !buildingName) {
    return <S.Wrapper />;
  }

  return (
    <S.Wrapper>
      <S.LocationIconWrapper>
        <Location />
      </S.LocationIconWrapper>
      <S.Address>{t('widget.header.apartment.address', { buildingAddress, buildingName })}</S.Address>
    </S.Wrapper>
  );
};

export default HeaderAddressMobile;
