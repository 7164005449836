import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0px;
  }
`;

export const Label = styled.label`
  margin: 10px 0px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
`;

export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  .MuiTextField-root {
    min-height: 40px;
  }
`;

export const QuestionsContainer = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
`;
