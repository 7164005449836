import styled from '@emotion/styled';

const APPOINTMENT_BLOCK_WIDTH = 394;

export const Wrapper = styled.div`
  display: grid;
  gap: 36px;
  grid-template-columns: repeat(auto-fill, ${APPOINTMENT_BLOCK_WIDTH}px);
  max-width: 100%;
  min-width: 0;
  padding: 0 16px;

  @media (max-width: 1100px) {
    gap: 16px;
    grid-template-columns: repeat(2, minmax(0, 1fr));

    padding: 0;
  }

  @media (max-width: 768px) {
    gap: 16px;
    grid-template-columns: 1fr;
    padding: 0px;
  }
`;
