import styled from '@emotion/styled';
import COLORS from '../../styles/colors';
import logo from '../../icons/el-logo.svg';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 30px 96px 30px 78px;
  align-items: center;
  background-color: ${COLORS.white};

  @media (max-width: 768px) {
    padding: 24px;
    height: 58px;
  }
`;

export const LogoWrapper = styled.div`
  background-image: url(${logo});
  height: 29px;
  width: 124px;
`;
