import { Dialog, DialogActions, DialogContent } from '@mui/material';
import styled from '@emotion/styled';
import { Button } from 'UI';
import colors from 'styles/colors';

export const DialogWrapper = styled(Dialog)``;

export const DialogHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 16px 12px 12px;
  padding: 0 16px;
`;

export const Title = styled.div`
  font-family: Outfit, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const DialogContentWrapper = styled(DialogContent)`
  padding: 12px 16px;
  margin: 0 16px;
  max-width: 380px;
`;

export const DialogActionsWrapper = styled(DialogActions)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 8px 16px 16px;
`;

export const RemoveCardButton = styled(Button)`
  align-items: center;
  background: ${colors.error300};
  border-radius: 4px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  justify-content: center;
  line-height: normal;
  padding: 14px 0;
  width: 100%;
  color: white;

  &:hover {
    background-color: ${colors.error300};
  }
`;

export const BackButton = styled(Button)`
  color: ${colors.grey80};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 10px 0;
  width: 100%;
`;
