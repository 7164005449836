import { useContext } from 'react';
import useTranslation from 'shared/useTranslation';
import { WidgetActionType } from 'Pages/Widget/Widget.store';
import { RecurrenceType } from 'Pages/Widget/Widget.types';
import { WidgetContext } from 'Pages/Widget/Widget.constants';
import SelectButton from 'UI/SelectButton';
import { AppointmentType, isAppointmentTypeOneTime, isAppointmentTypeRecurring } from 'shared/recurring';
import { getAppointmentRepeatModeWithDiscounts } from 'shared/recurrance';
import { useAppSelector } from 'hooks/hooks';
import { selectDiscounts } from 'store/discount/selectors';
import { useGetLowestVariantPrice } from 'hooks/variant';
import * as S from './styles';
import Recurrence from '../Recurrence';
import { formatLowestPrice } from './utils';

const RecurringPicker = () => {
  const { state, dispatch } = useContext(WidgetContext);
  const { selectedAppointmentType, selectedRecurrenceType, selectedVariantId } = state;
  const discounts = useAppSelector(selectDiscounts);
  const lowestPrice = useGetLowestVariantPrice();

  const [translations] = useTranslation([
    'widget.step.dateandtime.recurringpicker.choosetypeofappointment',
    'widget.step.dateandtime.recurringpicker.onetime',
    'widget.step.dateandtime.recurringpicker.or',
    'widget.step.dateandtime.recurringpicker.recurring',
    'widget.step.dateandtime.recurringpicker.repeatthisappointment',
    'widget.step.dateandtime.recurringpicker.times',
    'widget.step.dateandtime.recurringpicker.until',
    'widget.step.price.pre',
    'widget.step.variant.recurrence.title',
  ] as const);

  const onAppointmentTypeChange = (appointmentType: AppointmentType) =>
    dispatch({
      type: WidgetActionType.UPDATE_APPOINTMENT_TYPE,
      payload: { appointmentType },
    });

  const onRecurrenceChange = (recurrenceType: RecurrenceType) => {
    dispatch({
      type: WidgetActionType.UPDATE_RECURRENCE_TYPE,
      payload: { recurrenceType },
    });
  };

  return (
    <S.Wrapper>
      <S.Label>{translations.widgetStepDateandtimeRecurringpickerChoosetypeofappointment}</S.Label>
      <S.ButtonsWrapper>
        <SelectButton
          disabled={!selectedVariantId}
          isSelected={isAppointmentTypeOneTime(selectedAppointmentType)}
          text={translations.widgetStepDateandtimeRecurringpickerOnetime}
          type={AppointmentType.OneTime}
          onSelect={onAppointmentTypeChange}
        />
        <SelectButton
          disabled={!selectedVariantId || discounts.length === 0}
          isSelected={isAppointmentTypeRecurring(selectedAppointmentType)}
          price={formatLowestPrice(lowestPrice, translations.widgetStepPricePre)}
          text={translations.widgetStepDateandtimeRecurringpickerRecurring}
          type={AppointmentType.Recurring}
          onSelect={onAppointmentTypeChange}
        />
      </S.ButtonsWrapper>
      {isAppointmentTypeRecurring(selectedAppointmentType) ? (
        <>
          <S.Label>{translations.widgetStepVariantRecurrenceTitle}</S.Label>
          <S.RecurrenceWrapper>
            {getAppointmentRepeatModeWithDiscounts(discounts).map(({ recurrenceType, discount }) => (
              <Recurrence
                discount={discount}
                isSelected={recurrenceType === selectedRecurrenceType}
                key={recurrenceType}
                recurrenceType={recurrenceType}
                onSelect={onRecurrenceChange}
              />
            ))}
          </S.RecurrenceWrapper>
        </>
      ) : null}
    </S.Wrapper>
  );
};

export default RecurringPicker;
