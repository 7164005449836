import * as S from './SelectButton.styles';
import { SelectButtonProps } from './SelectButton.types';

const SelectButton = ({ disabled = false, isSelected, price = undefined, text, type, onSelect }: SelectButtonProps) => {
  const onAppointmentTypeChange = () => {
    if (disabled) {
      return;
    }
    onSelect(type);
  };

  if (price) {
    return (
      <S.SelectButton disabled={disabled} isSelected={isSelected} onClick={onAppointmentTypeChange}>
        <S.PriceContainer>
          <S.PriceLabel>{price}</S.PriceLabel>
        </S.PriceContainer>
        <S.Title>{text}</S.Title>
        <S.Selected isSelected={isSelected}>
          <S.SelectedIcon src={`${process.env.PUBLIC_URL}/icons/tick-small.svg`} />
        </S.Selected>
      </S.SelectButton>
    );
  }

  return (
    <S.SelectButton disabled={disabled} isSelected={isSelected} onClick={onAppointmentTypeChange}>
      <S.TitleCenter>{text}</S.TitleCenter>
      <S.Selected isSelected={isSelected}>
        <S.SelectedIcon src={`${process.env.PUBLIC_URL}/icons/tick-small.svg`} />
      </S.Selected>
    </S.SelectButton>
  );
};

export default SelectButton;
