import { createContext } from 'react';
import { AppointmentType } from 'shared/recurring';
import { RecurrenceType, WidgetContextInterface, WidgetStep, WidgetSteps } from './Widget.types';
import { WidgetState } from './Widget.store';

export const widgetSteps: WidgetSteps = [
  {
    type: WidgetStep.INDUSTRY,
  },
  {
    type: WidgetStep.APPOINTMENT,
  },
  {
    type: WidgetStep.VARIANT,
  },
  {
    type: WidgetStep.ADDON,
  },
  {
    type: WidgetStep.DATE_AND_TIME,
  },
  {
    type: WidgetStep.ADDITIONAL_INFORMATION,
  },
  {
    type: WidgetStep.SUMMARY,
  },
];

export const initialState: WidgetState = {
  appointmentQuestions: [],
  selectedAddonIds: [],
  selectedAppointmentId: undefined,
  selectedAppointmentType: AppointmentType.OneTime,
  selectedIndustryId: undefined,
  selectedRecurrenceType: RecurrenceType.Weekly,
  selectedRequestedDate: undefined,
  selectedRequestedTime: null,
  selectedTimeRange: undefined,
  selectedVariantId: undefined,

  currentStep: WidgetStep.INDUSTRY,
  widgetSteps,
  stepHistory: [],
};

export const WidgetContext = createContext<WidgetContextInterface>({
  state: initialState,
  dispatch: () => {},
});
