import { format, addMinutes, startOfDay, isToday, isAfter, startOfToday } from 'date-fns';
import { TimeRanges, TimeRange } from 'store/availableTimeRange/types';

export function displayTimeRange(dateStart: string, dateEnd: string) {
  return `${dateStart} - ${dateEnd}`;
}

export function getTimeFormatted(timeMin: number, dateNow = new Date()) {
  const dateFor = addMinutes(startOfDay(dateNow), timeMin);
  return format(dateFor, 'h:mm a');
}

const TIME_RANGE_SORTING_MAP: {
  [key in TimeRanges]: number;
} = {
  [TimeRanges.Anytime]: 1,
  [TimeRanges.EarlyMorning]: 2,
  [TimeRanges.Morning]: 3,
  [TimeRanges.Midday]: 4,
  [TimeRanges.Afternoon]: 5,
  [TimeRanges.Evening]: 6,
};

/* Sort `timeRange` by `key`. */
function compareAvailableTimeRanges(timeRangeLeft: TimeRange, timeRangeRight: TimeRange) {
  return TIME_RANGE_SORTING_MAP[timeRangeLeft.key] - TIME_RANGE_SORTING_MAP[timeRangeRight.key];
}

export function getSortedAvailableTimeRanges(availableTimeRanges: TimeRange[]) {
  return [...availableTimeRanges].sort(compareAvailableTimeRanges);
}

/* Filtered out `timeRanges` if they started already in past */
export function getFilteredAvailableTimeRanges(availableTimeRanges?: TimeRange[], requestedDate?: Date) {
  if (!availableTimeRanges || availableTimeRanges.length === 0 || !requestedDate) {
    return [];
  }

  const isDayToday = isToday(requestedDate);

  if (!isDayToday) {
    return availableTimeRanges;
  }

  return availableTimeRanges.filter((availableTimeRange) => {
    if (availableTimeRange.key === TimeRanges.Anytime) {
      return true;
    }
    const startTimeDate = addMinutes(startOfToday(), availableTimeRange.startTimeMin);
    return isAfter(startTimeDate, new Date());
  });
}
