import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import colors from 'styles/colors';

interface SelectedProps {
  disabled?: boolean;
  isSelected: boolean;
}

export const Selected = styled.div<SelectedProps>`
  align-items: center;
  background-color: ${colors.cornflowerBlue};
  border-bottom-left-radius: 8px;
  border-top-right-radius: 5px;
  display: ${({ isSelected }) => (isSelected ? 'flex' : 'none')};
  height: 100%;
  justify-content: center;
  max-height: 40px;
  max-width: 40px;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;

  @media (max-width: 768px) {
    font-size: 16px;
    font-weight: 500;
    max-height: 24px;
    max-width: 24px;
  }
`;

export const SelectedIcon = styled.img`
  width: 24px;
`;

export const SelectButton = styled.div<SelectedProps>`
  background-color: ${colors.white};
  border-radius: 8px;
  box-shadow: 0px 4px 10px ${({ isSelected }) => (!isSelected ? colors.blackSqueeze : colors.athensGray2)};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  display: flex;
  flex: 0 0 232px;
  flex-direction: column;
  font-weight: 500;
  height: 120px;
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  padding: 8px;
  position: relative;

  @media (max-width: 768px) {
    height: 88px;
    max-width: calc(50% - 8px);
    padding: 8px 24px 8px 16px;
  }
`;

export const PriceContainer = styled.div`
  display: inline-flex;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 2px 0;
`;

export const PriceLabel = styled(Typography)`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  background-color: ${colors.grey10};
  border-radius: 4px;
  color: ${colors.cornflowerBlue};
  display: -webkit-box;
  font-size: 10px;
  font-weight: 500;
  line-height: 24px;
  max-width: 100%;
  overflow: hidden;
  padding: 0 4px;
  text-overflow: ellipsis;
`;

export const Title = styled.div`
  align-items: center;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  justify-content: center;
  letter-spacing: 0px;
  line-height: 1.2;
  overflow: hidden;
  padding: 12px 0;
  text-overflow: ellipsis;
`;

export const TitleCenter = styled(Title)`
  align-items: center;
  flex: 1;
  justify-content: center;
`;
