import * as endpoints from '../apiUtils/endpoints';
import { api } from '../apiUtils/api';
import { WidgetResponse } from './widget.types';

export const widgetApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getWidgetDataByAppointmentGroups: builder.query<WidgetResponse, string | undefined>({
      query: (appointmentGroupId) => ({
        url: `${endpoints.appointmentGroups}/${appointmentGroupId}/${endpoints.widget}`,
      }),
    }),
    pay: builder.mutation<unknown, { appointmentGroupId?: number; creditCardId?: string }>({
      query: (dataForPay) => ({
        url: endpoints.lead,
        method: 'POST',
        body: dataForPay,
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        await queryFulfilled;
      },
    }),
    getClientSecret: builder.mutation<{ client_secret: string }, string>({
      query: (token) => ({
        url: `${process.env.REACT_APP_BACKEND_URL}client/setup-intent`,
        method: 'POST',
        body: { token },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        await queryFulfilled;
      },
    }),
  }),
  overrideExisting: true,
});

export const { useGetWidgetDataByAppointmentGroupsQuery, usePayMutation, useGetClientSecretMutation } = widgetApi;
