import styled from '@emotion/styled';

export const Container = styled.img`
  width: 20px;
  margin-right: 8px;

  @media (max-width: 768px) {
    width: 16px;
  }
`;
