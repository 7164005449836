export interface CreditCardInfo {
  id: string;
  object: string;
  address_city: string;
  address_country: string;
  address_line1: string;
  address_line1_check: string;
  address_line2: string;
  address_state: string;
  address_zip: string;
  address_zip_check: string;
  brand: CardType;
  country: string;
  customer: string;
  cvc_check: string;
  dynamic_last4: number;
  exp_month: number;
  exp_year: number;
  fingerprint: string;
  funding: string;
  last4: string;
  metadata: CardMetadata;
  name: string;
  tokenization_method: string;
  isValid: boolean;
  isDefault: boolean;
  creditCardId: string;
}

export enum CardType {
  Visa = 'Visa',
  AmericanExpress = 'American Express',
  Amex = 'Amex',
  DinersClub = 'Diners Club',
  Discover = 'Discover',
  JCB = 'JCB',
  MasterCard = 'MasterCard',
  Unionpay = 'Unionpay',
  Unknown = 'Unknown',
}

export interface CardMetadata {}
