import { useTranslation, TFunction } from 'react-i18next';
import trim from 'lodash/trim';
import { useMemo } from 'react';
import { ResponseAddon, ResponseVariant } from 'store/widget/widget.types';
import { NUMBER_OF_MINUTES_IN_HOUR } from './useDurationLabel.constants';

function formatDuration(duration: number, t: TFunction<'translation'>) {
  const durationHours = Math.floor(duration / NUMBER_OF_MINUTES_IN_HOUR);
  const durationMinutes = duration % NUMBER_OF_MINUTES_IN_HOUR;
  let resultLabel = '';

  if (durationMinutes) resultLabel = t('common.value.minutes', { minutes: durationMinutes });
  if (durationHours === 1) resultLabel = `${t('common.value.hour', { hour: durationHours })} ${resultLabel}`;
  if (durationHours > 1) resultLabel = `${t('common.value.hours', { hour: durationHours })} ${resultLabel}`;

  return trim(resultLabel);
}

export const useDurationLabel = (variant?: ResponseVariant, addons = [] as ResponseAddon[]) => {
  const { t } = useTranslation();

  const duration = useMemo(() => {
    const variantDurationMin = variant ? variant.durationMin : 0;
    const addonsDuration = addons.reduce((prev, acc) => prev + acc.durationMin, 0);

    return addonsDuration + variantDurationMin;
  }, [addons, variant]);

  return formatDuration(duration, t);
};
