import React, { useContext } from 'react';
import useTranslation from 'shared/useTranslation';
import { Button, Footer } from 'UI';
import { isMobile } from 'utils/responsive';
import { WidgetActionType } from 'Pages/Widget/Widget.store';
import { WidgetContext } from 'Pages/Widget/Widget.constants';
import { WidgetStep } from 'Pages/Widget/Widget.types';
import { getWidgetStepTitleTranslated } from 'Pages/Widget/Widget.utils';
import { NextStepButton } from 'styles/Buttons';
import ArrowBack from '../../atoms/ArrowBack';

const AddonStepFooter = () => {
  const { state, dispatch } = useContext(WidgetContext);
  const { selectedAppointmentType } = state;

  const handleGoNext = () => dispatch({ type: WidgetActionType.NEXT_STEP });
  const handleGoBack = () => dispatch({ type: WidgetActionType.PREVIOUS_STEP });

  const isMobileScreen = isMobile();

  const [translations, t] = useTranslation(['widget.step.back', 'widget.step.next'] as const);

  const buttonLabel = getWidgetStepTitleTranslated(t, WidgetStep.DATE_AND_TIME, {
    appointmentType: selectedAppointmentType,
  });

  return (
    <Footer>
      {!isMobileScreen ? (
        <Button color="secondary" onClick={handleGoBack}>
          <ArrowBack src={`${process.env.PUBLIC_URL}/icons/arrow-right-small.svg`} />
          {translations.widgetStepBack}
        </Button>
      ) : null}
      <NextStepButton variant="contained" color="primary" onClick={handleGoNext}>
        {isMobileScreen ? translations.widgetStepNext : buttonLabel}
      </NextStepButton>
    </Footer>
  );
};

export default AddonStepFooter;
