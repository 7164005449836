import { RecurrenceType } from 'Pages/Widget/Widget.types';
import { TFunction } from 'react-i18next';
import { Discount } from 'store/discount/types';

export function getDiscountByRecurrenceType(discounts: Discount[], recurrenceType: RecurrenceType | undefined) {
  const discount = discounts.find((discountToFind) => discountToFind.recurrenceType === recurrenceType);
  return discount?.discountPercent ?? 0;
}

export function getAppointmentRepeatModeWithDiscounts(discounts: Discount[]) {
  return discounts.map(({ recurrenceType, discountPercent }) => ({
    discount: discountPercent ?? 0,
    recurrenceType,
  }));
}

const TRANSLATION_MAP = {
  [RecurrenceType.Daily]: 'add.booking.form.price.per.day',
  [RecurrenceType.Weekly]: 'common.booking.recurrence.type.weekly',
  [RecurrenceType.Every4Weeks]: 'common.booking.recurrence.type.every.four.weeks',
  [RecurrenceType.Every2Weeks]: 'common.booking.recurrence.type.every.two.weeks',
  [RecurrenceType.Every3Weeks]: 'common.booking.recurrence.type.every.three.weeks',
};

export function getRecurrenceTypeTranslated(
  t: TFunction<'translation'>,
  recurrenceType: RecurrenceType = RecurrenceType.Weekly,
) {
  return t(TRANSLATION_MAP[recurrenceType] ?? TRANSLATION_MAP[RecurrenceType.Weekly]);
}
