if (process.env.REACT_APP_BACKEND_URL === null) throw new Error('Missing env.REACT_APP_BACKEND_URL');

export const backendUrl = process.env.REACT_APP_BACKEND_URL;

export const refreshToken = 'auth/refresh';

export const login = 'auth/login/client';

export const client = 'client';

export const Appointments = 'appointment';

export const appointmentGroups = 'appointment-group';

export const widget = 'widget';

export const lead = 'lead';
