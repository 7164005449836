import { ReactComponent as CreditCardIcon } from 'icons/creditCard.svg';
import { ReactComponent as ArrowRightIcon } from 'icons/arrowRight.svg';
import useTranslation from 'shared/useTranslation';
import * as S from './styles';
import { SummaryPaymentFooterProps } from './types';

const SummaryPaymentFooter = ({ price, onAddNewCardClick }: SummaryPaymentFooterProps) => {
  const [translations] = useTranslation([
    'widget.step.payment.add.new.card',
    'widget.step.summary.authorization.hold.notice',
    'widget.step.summary.information',
  ] as const);

  return (
    <>
      {price !== 0 ? (
        <S.AddNewCard onClick={onAddNewCardClick}>
          <CreditCardIcon />
          <S.AddNewCardText>{translations.widgetStepPaymentAddNewCard}</S.AddNewCardText>
          <ArrowRightIcon />
        </S.AddNewCard>
      ) : null}
      {price ? <S.TextNote>{translations.widgetStepSummaryAuthorizationHoldNotice}</S.TextNote> : null}
      <S.SummaryInformation>{translations.widgetStepSummaryInformation}</S.SummaryInformation>
    </>
  );
};

export default SummaryPaymentFooter;
