import { Time } from 'UI/TimePicker/constants';
import { TimeRanges } from 'store/availableTimeRange/types';
import { AppointmentType } from 'shared/recurring';
import { AppointmentQuestion, RecurrenceType, WidgetStep, WidgetSteps } from './Widget.types';
import { initialState } from './Widget.constants';

export enum WidgetActionType {
  NEXT_STEP = 'NEXT_STEP',
  PREVIOUS_STEP = 'PREVIOUS_STEP',
  UPDATE_ADDITIONAL_INFO_STEP_NOTE = 'UPDATE_ADDITIONAL_INFO_STEP_NOTE',
  UPDATE_ADDITIONAL_INFO_STEP_QUESTION = 'UPDATE_ADDITIONAL_INFO_STEP_QUESTION',
  UPDATE_ADDON_STEP = 'UPDATE_ADDON_STEP',
  UPDATE_APPOINTMENT_STEP = 'UPDATE_APPOINTMENT_STEP',
  UPDATE_APPOINTMENT_TYPE = 'UPDATE_APPOINTMENT_TYPE',
  UPDATE_INDUSTRY_STEP = 'UPDATE_INDUSTRY_STEP',
  UPDATE_RECURRENCE_TYPE = 'UPDATE_RECURRENCE_TYPE',
  UPDATE_TIME_RANGE = 'UPDATE_TIME_RANGE',
  UPDATE_VARIANT_STEP = 'UPDATE_VARIANT_STEP',
  UPDATE_WIDGET = 'UPDATE_WIDGET',
  UPDATE_REQUESTED_TIME = 'UPDATE_REQUESTED_TIME',
  UPDATE_REQUESTED_DATE = 'UPDATE_REQUESTED_DATE',
  INITIATE_APPOINTMENT_GROUP_WITH_INDUSTRY_WIDGET = 'INITIATE_APPOINTMENT_GROUP_WITH_INDUSTRY_WIDGET',
  GO_TO_SUMMARY = 'GO_TO_SUMMARY',
  RESTART_WIDGET_CREATION = 'RESTART_WIDGET_CREATION',
  GO_TO_PAYMENT_SUCCESS = 'GO_TO_PAYMENT_SUCCESS',
  GO_TO_PAYMENT_ERROR = 'GO_TO_PAYMENT_ERROR',
  GO_TO_ADDONS = 'GO_TO_ADDONS',
  GO_TO_DATE_AND_TIME = 'GO_TO_DATE_AND_TIME',
  GO_TO_VARIANTS = 'GO_TO_VARIANTS',
  GO_TO_ADDITIONAL_INFORMATION = 'GO_TO_ADDITIONAL_INFORMATION',
}

export interface WidgetState {
  appointmentQuestions: AppointmentQuestion[];
  selectedAddonIds: number[];
  selectedAppointmentId: number | undefined;
  selectedAppointmentType: AppointmentType;
  selectedIndustryId: number | undefined;
  selectedRecurrenceType: RecurrenceType;
  selectedRequestedDate: Date | undefined; // date for time picker
  selectedRequestedTime: Time | null; // time for time picker
  selectedTimeRange: TimeRanges | undefined;
  selectedVariantId: number | undefined;

  currentStep: WidgetStep;
  stepHistory: WidgetStep[];
  widgetSteps: WidgetSteps;
}

export type WidgetAction =
  | {
      type: WidgetActionType.UPDATE_INDUSTRY_STEP;
      payload: {
        industryId: number;
      };
    }
  | {
      type: WidgetActionType.UPDATE_APPOINTMENT_STEP;
      payload: {
        appointmentQuestions: AppointmentQuestion[];
        appointmentId: number;
      };
    }
  | {
      type: WidgetActionType.UPDATE_VARIANT_STEP;
      payload: {
        variantId: number;
      };
    }
  | {
      type: WidgetActionType.UPDATE_ADDON_STEP;
      payload: {
        addonId: number;
      };
    }
  | {
      type: WidgetActionType.UPDATE_APPOINTMENT_TYPE;
      payload: {
        appointmentType: AppointmentType;
      };
    }
  | {
      type: WidgetActionType.UPDATE_RECURRENCE_TYPE;
      payload: {
        recurrenceType: RecurrenceType;
      };
    }
  | {
      type: WidgetActionType.UPDATE_TIME_RANGE;
      payload: {
        timeRange: TimeRanges;
      };
    }
  | {
      type: WidgetActionType.UPDATE_REQUESTED_TIME;
      payload: {
        requestedTime: Time | null;
      };
    }
  | {
      type: WidgetActionType.UPDATE_REQUESTED_DATE;
      payload: {
        requestedDate: Date;
      };
    }
  | {
      type: WidgetActionType.GO_TO_SUMMARY;
      payload?: {
        isAdditionalStep: boolean;
      };
    }
  | {
      type: WidgetActionType.GO_TO_DATE_AND_TIME;
      payload: {
        isAdditionalStep: boolean;
      };
    }
  | {
      type: WidgetActionType.UPDATE_ADDITIONAL_INFO_STEP_QUESTION;
      payload: {
        answer: string;
        questionId: number;
      };
    }
  | {
      type: WidgetActionType.INITIATE_APPOINTMENT_GROUP_WITH_INDUSTRY_WIDGET;
      payload: {
        industryId: number;
      };
    }
  | {
      type:
        | WidgetActionType.GO_TO_ADDITIONAL_INFORMATION
        | WidgetActionType.GO_TO_ADDONS
        | WidgetActionType.GO_TO_PAYMENT_ERROR
        | WidgetActionType.GO_TO_PAYMENT_SUCCESS
        | WidgetActionType.GO_TO_VARIANTS
        | WidgetActionType.NEXT_STEP
        | WidgetActionType.PREVIOUS_STEP
        | WidgetActionType.RESTART_WIDGET_CREATION;
      payload?: {};
    };

export function widgetReducer(state: WidgetState, action: WidgetAction): WidgetState {
  const { type } = action;
  switch (type) {
    case WidgetActionType.UPDATE_INDUSTRY_STEP: {
      const { industryId } = action.payload;
      return {
        ...state,
        currentStep: state.currentStep + 1,
        selectedIndustryId: industryId,
        stepHistory: [...state.stepHistory, state.currentStep + 1],
      };
    }
    case WidgetActionType.UPDATE_APPOINTMENT_STEP: {
      const { appointmentId, appointmentQuestions } = action.payload;
      return {
        ...state,
        appointmentQuestions,
        currentStep: state.currentStep + 1,
        selectedAppointmentId: appointmentId,
        selectedAppointmentType: AppointmentType.OneTime,
        selectedRecurrenceType: RecurrenceType.Weekly,
        selectedVariantId: undefined,
        stepHistory: [...state.stepHistory, state.currentStep + 1],
      };
    }
    case WidgetActionType.UPDATE_VARIANT_STEP: {
      const { variantId } = action.payload;
      return {
        ...state,
        selectedVariantId: variantId,
      };
    }
    case WidgetActionType.UPDATE_ADDON_STEP: {
      const { addonId } = action.payload;
      if (!addonId) {
        return state;
      }
      const addonIndex = state.selectedAddonIds.findIndex((selectedAddonId) => selectedAddonId === addonId);

      return {
        ...state,
        selectedAddonIds:
          addonIndex === -1
            ? [...state.selectedAddonIds, addonId]
            : [...state.selectedAddonIds.slice(0, addonIndex), ...state.selectedAddonIds.slice(addonIndex + 1)],
      };
    }
    case WidgetActionType.UPDATE_ADDITIONAL_INFO_STEP_QUESTION: {
      const { answer, questionId } = action.payload;
      return {
        ...state,
        appointmentQuestions: state.appointmentQuestions.map((appointmentQuestion) =>
          appointmentQuestion.id === questionId
            ? {
                ...appointmentQuestion,
                answer,
              }
            : appointmentQuestion,
        ),
      };
    }
    case WidgetActionType.UPDATE_APPOINTMENT_TYPE: {
      const { appointmentType } = action.payload;
      return {
        ...state,
        selectedAppointmentType: appointmentType,
      };
    }
    case WidgetActionType.UPDATE_RECURRENCE_TYPE: {
      const { recurrenceType } = action.payload;
      return {
        ...state,
        selectedRecurrenceType: recurrenceType,
      };
    }
    case WidgetActionType.UPDATE_TIME_RANGE: {
      const { timeRange } = action.payload;
      return {
        ...state,
        selectedTimeRange: timeRange,
      };
    }
    case WidgetActionType.UPDATE_REQUESTED_TIME: {
      const { requestedTime } = action.payload;
      return {
        ...state,
        selectedRequestedTime: requestedTime,
      };
    }
    case WidgetActionType.UPDATE_REQUESTED_DATE: {
      const { requestedDate } = action.payload;
      return {
        ...state,
        selectedRequestedDate: requestedDate,
      };
    }
    case WidgetActionType.NEXT_STEP:
      return {
        ...state,
        stepHistory: [...state.stepHistory, state.currentStep + 1],
        currentStep: state.currentStep + 1,
      };
    case WidgetActionType.PREVIOUS_STEP: {
      const nextHistory = state.stepHistory.slice(0, -1);
      const previousStep = nextHistory.length > 0 ? nextHistory[nextHistory.length - 1] : WidgetStep.INDUSTRY;
      return {
        ...state,
        stepHistory: nextHistory,
        currentStep: previousStep,
      };
    }
    case WidgetActionType.GO_TO_ADDONS: {
      return {
        ...state,
        stepHistory: [...state.stepHistory, WidgetStep.ADDON],
        currentStep: WidgetStep.ADDON,
      };
    }
    case WidgetActionType.GO_TO_DATE_AND_TIME: {
      const { isAdditionalStep } = action.payload;
      return {
        ...state,
        stepHistory: [...state.stepHistory, ...(isAdditionalStep ? [WidgetStep.ADDON] : []), WidgetStep.DATE_AND_TIME],
        currentStep: WidgetStep.DATE_AND_TIME,
      };
    }
    case WidgetActionType.GO_TO_ADDITIONAL_INFORMATION:
      return {
        ...state,
        stepHistory: [...state.stepHistory, WidgetStep.ADDITIONAL_INFORMATION],
        currentStep: WidgetStep.ADDITIONAL_INFORMATION,
      };
    case WidgetActionType.GO_TO_VARIANTS:
      return {
        ...state,
        stepHistory: [...state.stepHistory, WidgetStep.VARIANT],
        currentStep: WidgetStep.VARIANT,
      };
    case WidgetActionType.GO_TO_PAYMENT_SUCCESS:
      return {
        ...state,
        stepHistory: [],
        currentStep: WidgetStep.PAYMENT_SUCCESS,
      };
    case WidgetActionType.GO_TO_PAYMENT_ERROR:
      return {
        ...state,
        currentStep: WidgetStep.PAYMENT_ERROR,
      };
    case WidgetActionType.GO_TO_SUMMARY: {
      const { isAdditionalStep = false } = action.payload || {};
      return {
        ...state,
        stepHistory: [
          ...state.stepHistory,
          ...(isAdditionalStep ? [WidgetStep.ADDITIONAL_INFORMATION] : []),
          WidgetStep.SUMMARY,
        ],
        currentStep: WidgetStep.SUMMARY,
      };
    }
    case WidgetActionType.RESTART_WIDGET_CREATION:
      return {
        ...state,
        ...initialState,
        // save old value as `selected`.
        selectedIndustryId: state.selectedIndustryId,
      };
    case WidgetActionType.INITIATE_APPOINTMENT_GROUP_WITH_INDUSTRY_WIDGET: {
      const { industryId } = action.payload;
      return {
        ...state,
        selectedIndustryId: industryId,
        currentStep: WidgetStep.APPOINTMENT,
        stepHistory: [...state.stepHistory, state.currentStep + 1],
      };
    }
    default:
      return state;
  }
}
