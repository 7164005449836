import React from 'react';
import { ArrowBack } from 'UI';
import useTranslation from 'shared/useTranslation';
import { SummaryPaymentFooterProps } from './SummaryPaymentFooter.types';
import * as S from './SummaryPaymentFooter.styles';
import NextButton from '../../atoms/NextButton';
import BackButton from '../../atoms/BackButton';

const SummaryPaymentFooter = ({
  isDisabled,
  loading,
  onGoBackClick,
  onProceedPaymentClick,
}: SummaryPaymentFooterProps) => {
  const [translations] = useTranslation(['widget.step.summary.payment.proceed.payment', 'widget.step.back'] as const);
  return (
    <S.Container>
      <BackButton color="secondary" onClick={onGoBackClick}>
        <ArrowBack src={`${process.env.PUBLIC_URL}/icons/arrow-right-small.svg`} />
        {translations.widgetStepBack}
      </BackButton>
      <NextButton
        color="primary"
        disabled={isDisabled}
        loading={loading}
        variant="contained"
        onClick={onProceedPaymentClick}
      >
        {translations.widgetStepSummaryPaymentProceedPayment}
      </NextButton>
    </S.Container>
  );
};

export default SummaryPaymentFooter;
