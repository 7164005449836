import { useContext } from 'react';
import { WidgetContext } from 'Pages/Widget/Widget.constants';
import { WidgetStep } from 'Pages/Widget/Widget.types';
import { isIframe } from 'utils/responsive';
import { StepTitle } from 'UI';
import useTranslation from 'shared/useTranslation';
import { getWidgetStepTitleTranslated } from 'Pages/Widget/Widget.utils';
import { StepHeaderProps } from './types';
import * as S from './styles';

const StepHeader = ({ stepNumber }: StepHeaderProps) => {
  const { state } = useContext(WidgetContext);
  const { selectedAppointmentType } = state;
  const [, t] = useTranslation([]);

  const hideInfoBlock =
    (isIframe() && WidgetStep.INDUSTRY === stepNumber) ||
    [WidgetStep.PAYMENT_SUCCESS, WidgetStep.PAYMENT_ERROR].includes(stepNumber);

  if (hideInfoBlock) {
    return null;
  }

  const title = getWidgetStepTitleTranslated(t, stepNumber, { appointmentType: selectedAppointmentType });
  return (
    <S.Container>
      <S.InfoBlock>
        <StepTitle>{title}</StepTitle>
      </S.InfoBlock>
    </S.Container>
  );
};

export default StepHeader;
