import styled from '@emotion/styled';
import COLORS from 'styles/colors';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flew-wrap: wrap;
  gap: 16px;
`;

export const Label = styled.label`
  align-items: flex-end;
  color: ${COLORS.black}
  display: flex;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  margin: 10px 0px;
`;

export const SecondaryText = styled.p`
  color: ${COLORS.grey80};
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin: 10px 0px;
  padding: 0px 8px;
`;

export const RecurrenceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  flex-wrap: wrap;
`;
