import { Dialog, DialogActions, DialogContent } from '@mui/material';
import styled from '@emotion/styled';

export const DialogWrapper = styled(Dialog)``;

export const DialogHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 16px 12px 12px;
  padding: 0 16px;
`;

export const Title = styled.div`
  font-family: Outfit, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const DialogContentWrapper = styled(DialogContent)`
  padding: 12px 16px;
  margin: 0 16px;

  @media (max-width: 960px) {
    margin: 0 12px;
  }
`;

export const DialogActionsWrapper = styled(DialogActions)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 8px 16px 16px;
`;
