import HeaderAddressMobile from 'Pages/Widget/components/HeaderAddressMobile/HeaderAddressMobile.component';
import { useMediaQuery } from 'shared/useMediaQuery';
import { RESOLUTIONS } from 'utils/responsive';
import { HeaderProps } from './Header.types';
import * as S from './Header.styles';

const Header = ({ children }: HeaderProps) => {
  const isMobile = useMediaQuery(RESOLUTIONS.MOBILE_RESOLUTION);

  return (
    <S.Container>
      {isMobile ? <HeaderAddressMobile /> : <S.LogoWrapper />}
      {children}
    </S.Container>
  );
};

export default Header;
