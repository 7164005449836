import { TFunction } from 'react-i18next';
import { TimeRanges } from 'store/availableTimeRange/types';

export const TIME_RANGE_ANYTIME_DISCOUNT = 5;

const TIME_RANGE_MAP: {
  [key in TimeRanges]: string;
} = {
  [TimeRanges.Anytime]: 'widget.step.dateandtime.timeslots.anytime',
  [TimeRanges.EarlyMorning]: 'widget.step.dateandtime.timeslots.earlymorning',
  [TimeRanges.Morning]: 'widget.step.dateandtime.timeslots.morning',
  [TimeRanges.Midday]: 'widget.step.dateandtime.timeslots.midday',
  [TimeRanges.Afternoon]: 'widget.step.dateandtime.timeslots.afternoon',
  [TimeRanges.Evening]: 'widget.step.dateandtime.timeslots.evening',
};

export function getTimeRangeTranslated(timeRange: TimeRanges | undefined, t: TFunction<'translation'>) {
  return t(TIME_RANGE_MAP[timeRange ?? TimeRanges.Anytime]);
}
