import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  flex-shrink: 0;
  gap: 16px;
  justify-content: space-between;
  padding: 16px 0 32px 0;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
