import { createSlice } from '@reduxjs/toolkit';
import { widgetApi } from '../widget/widget.api';
import { AvailableTimeRangeReducerState } from './types';

const getInitialState = (): AvailableTimeRangeReducerState => ({
  availableTimeRanges: [],
  enableAnytimeTimeRange: false,
  hasTimeRangeSelection: false,
});

const appointmentSlice = createSlice({
  name: 'appointmentReducer',
  initialState: getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(widgetApi.endpoints.getWidgetDataByAppointmentGroups.matchFulfilled, (state, { payload }) => {
      state.availableTimeRanges = payload.availableTimeRanges;
      state.enableAnytimeTimeRange = payload.enableAnytimeTimeRange;
      state.hasTimeRangeSelection = payload.hasTimeRangeSelection;
    });
  },
});

const { reducer } = appointmentSlice;

export default reducer;
