import styled from '@emotion/styled';
import colors from 'styles/colors';
import { Typography } from '@mui/material';

export const SelectBlockContainer = styled.div`
  background-color: ${colors.white};
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  flex: 0 0 232px;
  gap: 8px;
  height: 120px;
  justify-content: space-between;
  padding: 24px 40px 24px 24px;
  position: relative;

  &:hover {
    box-shadow: 0px 4px 12px ${colors.blackSqueeze};
  }

  @media (max-width: 768px) {
    height: 88px;
    max-width: calc(50% - 8px);
    padding: 8px 24px 8px 16px;
  }
`;

export const Title = styled(Typography)`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 1.2;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SubTitle = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 12px;
  margin: 0px;
  max-width: 400px;
`;
