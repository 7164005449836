import { useContext } from 'react';
import useTranslation from 'shared/useTranslation';
import { WidgetActionType } from 'Pages/Widget/Widget.store';
import { WidgetContext } from 'Pages/Widget/Widget.constants';
import { useFetchWidget } from 'hooks/useFetchWidget';
import { Box, Button } from '@mui/material';
import { BOX_WRAPPER_STYLES } from 'shared/styles';
import PaymentStatusWrapper from 'UI/PaymentStatusWrapper';

const PaymentErrorStep = (): JSX.Element => {
  const { dispatch } = useContext(WidgetContext);
  const [translations] = useTranslation([
    'widget.step.payment.error.back',
    'widget.step.payment.error.title',
    'widget.step.payment.error.subtitle',
  ] as const);

  const { refetch: refetchWidget } = useFetchWidget();

  const onBackButtonClick = async () => {
    await refetchWidget();
    dispatch({ type: WidgetActionType.GO_TO_SUMMARY });
  };

  return (
    <PaymentStatusWrapper
      title={translations.widgetStepPaymentErrorTitle}
      subtitle={translations.widgetStepPaymentErrorSubtitle}
      icon={`${process.env.PUBLIC_URL}/icons/payment-error.svg`}
    >
      <Box sx={BOX_WRAPPER_STYLES}>
        <Button variant="contained" color="secondary" onClick={onBackButtonClick}>
          {translations.widgetStepPaymentErrorBack}
        </Button>
      </Box>
    </PaymentStatusWrapper>
  );
};

export default PaymentErrorStep;
