import { FormikProvider, useFormik } from 'formik';
import useTranslation from 'shared/useTranslation';
import { Button } from 'UI';
import { registerFormikTextField } from 'shared/getters/getFormikProps';
import { loginValidationSchema, TEST_IDS, PLACEHOLDER_EMAIL, PLACEHOLDER_PASSWORD } from './constants';
import { useLoginAuth } from './hooks';
import * as S from './styles';
import { LoginFormFields } from './types';
import FieldsContainer from './atoms/FieldsContainer';
import LoginTextField from './atoms/LoginTextField';
import ButtonContainer from './atoms/ButtonContainer';

const LoginPage = (): JSX.Element => {
  const [translations] = useTranslation([
    'page.login.sign.in',
    'page.login.username',
    'page.login.title',
    'page.login.password',
  ] as const);

  const [login, { isLoading }] = useLoginAuth();

  const formik = useFormik<LoginFormFields>({
    initialValues: { email: '', password: '' },
    validationSchema: loginValidationSchema,
    onSubmit: login,
  });

  return (
    <S.Wrapper>
      <S.ImgWrapper>
        <S.Img src={`${process.env.PUBLIC_URL}/logoblack192.svg`} />
      </S.ImgWrapper>
      <FormikProvider value={formik}>
        <S.LoginContainer>
          <FieldsContainer>
            <LoginTextField
              data-test-id={TEST_IDS.emailInput}
              disabled={isLoading}
              fullWidth
              label={translations.pageLoginUsername}
              placeholder={PLACEHOLDER_EMAIL}
              variant="filled"
              {...registerFormikTextField('email', formik)}
            />
            <LoginTextField
              data-test-id={TEST_IDS.passwordInput}
              disabled={isLoading}
              fullWidth
              label={translations.pageLoginPassword}
              placeholder={PLACEHOLDER_PASSWORD}
              type="password"
              variant="filled"
              {...registerFormikTextField('password', formik)}
            />
          </FieldsContainer>
          <ButtonContainer>
            <Button
              color="info"
              data-test-id={TEST_IDS.signinButton}
              fullWidth
              loading={isLoading}
              variant="contained"
              type="submit"
            >
              {translations.pageLoginSignIn}
            </Button>
          </ButtonContainer>
        </S.LoginContainer>
      </FormikProvider>
    </S.Wrapper>
  );
};

export default LoginPage;
