import { isNumber } from 'lodash';
import { WidgetAction } from 'Pages/Widget/Widget.store';
import { Dispatch, useEffect, useMemo } from 'react';
import toast from 'react-hot-toast';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { isServerErrorWithMessage } from 'shared/ErrorTypeCheck';
import { useGetWidgetDataByAppointmentGroupsQuery } from 'store/widget/widget.api';
import { WidgetActionType } from './Widget.store';

export const useGetIndustryId = () => {
  const { search } = useLocation();

  return useMemo(() => {
    const query = new URLSearchParams(search);
    const queryIndustryId = Number(query.get('industryId'));

    return isNumber(queryIndustryId) ? queryIndustryId : undefined;
  }, [search]);
};

export const useGetWidgetData = (dispatch: Dispatch<WidgetAction>) => {
  const { appointmentGroupId } = useParams<{ appointmentGroupId: string }>();
  const navigate = useNavigate();

  const industryId = useGetIndustryId();

  const { data, isLoading, error } = useGetWidgetDataByAppointmentGroupsQuery(appointmentGroupId, {
    skip: !appointmentGroupId,
  });

  useEffect(() => {
    if (appointmentGroupId && isServerErrorWithMessage(error)) {
      toast.error(error.data.message);
    }
  }, [error, appointmentGroupId]);

  useEffect(() => {
    if (appointmentGroupId && data) {
      const isIndustryValid = data.industries.some(({ id }) => id === industryId);

      if (isIndustryValid && industryId) {
        dispatch({
          type: WidgetActionType.INITIATE_APPOINTMENT_GROUP_WITH_INDUSTRY_WIDGET,
          payload: {
            industryId,
          },
        });
        return;
      }
      navigate(`${process.env.PUBLIC_URL}/${appointmentGroupId}`, { replace: true });
    }
  }, [data, appointmentGroupId, dispatch, industryId, navigate]);

  return { data, isLoading };
};
