import React, { useEffect } from 'react';
import format from 'date-fns/format';
import { CalendarAction, CustomToolbarProps } from './CustomToolbar.types';
import * as S from './CustomToolbar.styles';

const CustomCalendarToolbar = (toolbar: CustomToolbarProps) => {
  const goToBack = () => {
    toolbar.onNavigate(CalendarAction.PREV);
  };

  const goToNext = () => {
    toolbar.onNavigate(CalendarAction.NEXT);
  };

  const goToCurrent = () => {
    toolbar.onNavigate(CalendarAction.TODAY);
  };

  const date = new Date(toolbar.date);
  const month = format(date, 'MMMM');
  const year = format(date, 'yyyy');

  useEffect(() => {
    toolbar.onNavigate(CalendarAction.TODAY);
  }, []);

  return (
    <S.Header>
      <S.DateButton color="info" onClick={goToCurrent}>
        {month} {year}
      </S.DateButton>
      <S.CenterWrapper>
        <S.StyledButton color="info" onClick={goToBack}>
          <img src={`${process.env.PUBLIC_URL}/icons/calendar-arrow-back.svg`} />
        </S.StyledButton>
        <S.StyledButton color="info" onClick={goToNext}>
          <img src={`${process.env.PUBLIC_URL}/icons/calendar-arrow-next.svg`} />
        </S.StyledButton>
      </S.CenterWrapper>
    </S.Header>
  );
};

export default CustomCalendarToolbar;
