import { Card } from 'UI';
import { CreditCardInfo } from 'store/client/client.types';
import useTranslation from 'shared/useTranslation';
import { useMediaQuery } from 'shared/useMediaQuery';
import { RESOLUTIONS } from 'utils/responsive';
import * as S from './styles';
import StripePaymentForm from '../../../StripePaymentForm';
import { CREDIT_CARD_ID_NONE } from '../../constants';
import { CardsListProps } from './types';

const CardsList = ({
  price,
  isLoading,
  paymentCards,
  selectedPaymentCardId,
  onCardSelect,
  onCardInfoChange,
  onCardDetailsOpen,
}: CardsListProps) => {
  const [translations] = useTranslation(['widget.step.payment.card', 'widget.step.payment.add.new.card'] as const);
  const isMobile = useMediaQuery(RESOLUTIONS.MOBILE_RESOLUTION);

  const renderPaymentCards = () => {
    if (isLoading) {
      return <S.SummaryPaymentLoader />;
    }

    return (
      <S.PaymentCards>
        {paymentCards.map((creditCard) => (
          <Card
            cardData={creditCard as CreditCardInfo}
            isSelected={creditCard.creditCardId === selectedPaymentCardId}
            key={creditCard.creditCardId}
            onCardClick={onCardSelect}
            onCardDetailsOpen={onCardDetailsOpen}
          />
        ))}
      </S.PaymentCards>
    );
  };

  const renderStripeAddNewCardField = () => {
    if (isMobile) {
      return null;
    }

    return (
      <S.AddNewCardWrapper>
        <S.PaymentCardTitle>{translations.widgetStepPaymentAddNewCard}</S.PaymentCardTitle>
        <S.PaymentCards>
          <StripePaymentForm id={CREDIT_CARD_ID_NONE} onCardInfoChange={onCardInfoChange} onCardClick={onCardSelect} />
        </S.PaymentCards>
      </S.AddNewCardWrapper>
    );
  };

  // Note: If the price is 0 we don't render cards
  if (price === 0) {
    return null;
  }

  return (
    <S.CardsWrapper>
      <S.PaymentCardsWrapper>
        <S.PaymentCardTitle>{translations.widgetStepPaymentCard}</S.PaymentCardTitle>
        {renderPaymentCards()}
      </S.PaymentCardsWrapper>
      {renderStripeAddNewCardField()}
    </S.CardsWrapper>
  );
};

export default CardsList;
