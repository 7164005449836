import { Elements } from '@stripe/react-stripe-js';

import { loadStripe } from '@stripe/stripe-js';
import { ReactElement } from 'react';

const stripePromise =
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY && loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const StripeMiddleware = ({ children }: { children: ReactElement }) => {
  if (!stripePromise) {
    return null;
  }
  return <Elements stripe={stripePromise}>{children}</Elements>;
};

export default StripeMiddleware;
