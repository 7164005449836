import { add, differenceInWeeks, isEqual } from 'date-fns';

export const getDifferenceInWeeksFromRequestedToCurrentDate = (currentDate: Date, requestedDate: Date) => {
  const diffInWeeks = differenceInWeeks(currentDate, requestedDate);
  const nextRecurringDate = add(requestedDate, { weeks: diffInWeeks });

  return {
    isCurrentDayMatchesNextRecurringWeekStartDay: isEqual(nextRecurringDate, currentDate),
    diffInWeeks,
  };
};
