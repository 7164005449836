import { CardElement } from '@stripe/react-stripe-js';
import { StripePaymentFormProps } from './StripePaymentForm.types';
import * as S from './StripePaymentForm.styles';

const StripePaymentForm = ({ id, onCardClick, onCardInfoChange }: StripePaymentFormProps) => {
  const onClick = () => onCardClick && onCardClick(id);

  return (
    <S.Wrapper onClick={onClick}>
      <S.Container>
        <S.StripePaymentForm>
          <S.CardInput>
            <CardElement
              onFocus={onClick}
              onChange={onCardInfoChange}
              options={{
                style: S.CardStyles,
                iconStyle: 'solid',
                hidePostalCode: true,
              }}
            />
          </S.CardInput>
        </S.StripePaymentForm>
      </S.Container>
    </S.Wrapper>
  );
};

export default StripePaymentForm;
