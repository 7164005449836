import styled from '@emotion/styled';
import COLORS from 'styles/colors';
import { IconSelectedProps } from './types';

export const Wrapper = styled.div<IconSelectedProps>`
  align-items: center;
  background-color: ${COLORS.cornflowerBlue};
  border-bottom-left-radius: 8px;
  border-top-right-radius: 8px;
  display: ${({ selected }) => (selected ? 'flex' : 'none')};
  height: 100%;
  justify-content: center;
  max-height: 40px;
  max-width: 40px;
  width: 100%;
  position: absolute;
  right: 0;
  top: 0;

  @media (max-width: 768px) {
    align-items: center;
    display: ${({ selected }) => (selected ? 'flex' : 'none')};
    font-size: 16px;
    font-weight: 500;
    justify-content: center;
    max-height: 24px;
    max-width: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: break-spaces;
  }
`;

export const Icon = styled.img`
  width: 24px;
`;
