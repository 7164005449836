import { WidgetContext } from 'Pages/Widget/Widget.constants';
import { useContext } from 'react';
import { WidgetActionType } from 'Pages/Widget/Widget.store';
import { useAppSelector } from 'hooks/hooks';
import { selectAvailableTimeRanges, selectEnableAnytimeTimeRangeSelection } from 'store/availableTimeRange/selectors';
import { TimeRanges } from 'store/availableTimeRange/types';
import TimeRange from '../TimeRange';
import * as S from './styles';
import {
  displayTimeRange,
  getFilteredAvailableTimeRanges,
  getSortedAvailableTimeRanges,
  getTimeFormatted,
} from './utils';

const TimeRangesComponent = () => {
  const { state, dispatch } = useContext(WidgetContext);
  const { selectedTimeRange, selectedRequestedDate } = state;

  const availableTimeRanges = useAppSelector(selectAvailableTimeRanges);
  const enableAnytimeRange = useAppSelector(selectEnableAnytimeTimeRangeSelection);

  const onTimeRangeSelect = (timeRange: TimeRanges) => {
    dispatch({
      type: WidgetActionType.UPDATE_TIME_RANGE,
      payload: { timeRange },
    });
  };

  const availableTimeRangesFiltered = getFilteredAvailableTimeRanges(availableTimeRanges, selectedRequestedDate);
  const sortedAvailableTimeRanges = getSortedAvailableTimeRanges(availableTimeRangesFiltered);

  return (
    <S.Wrapper>
      {sortedAvailableTimeRanges.map((availableTimeRange) => (
        <TimeRange
          key={availableTimeRange.key}
          selected={availableTimeRange.key === selectedTimeRange}
          showDiscount={enableAnytimeRange && availableTimeRange.key === TimeRanges.Anytime}
          time={displayTimeRange(
            getTimeFormatted(availableTimeRange.startTimeMin),
            getTimeFormatted(availableTimeRange.endTimeMin),
          )}
          timeRange={availableTimeRange.key}
          onSelect={onTimeRangeSelect}
        />
      ))}
    </S.Wrapper>
  );
};

export default TimeRangesComponent;
