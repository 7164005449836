import { SelectorValue } from 'UI/Selector/Selector.types';
import set from 'date-fns/set';
import { zonedTimeToUtc } from 'date-fns-tz';

export const generateYearsList: () => SelectorValue[] = () => {
  let currentYear = new Date().getFullYear();
  const lastYear = currentYear + 10;
  const listOfYears: { value: string; id: number }[] = [];
  let id = 0;
  while (currentYear <= lastYear) {
    listOfYears.push({ value: currentYear.toString(), id });
    currentYear += 1;
    id += 1;
  }
  return listOfYears;
};

export const generateTimesArray: () => SelectorValue[] = () => {
  const timesArray = [];
  for (let index = 0; index < 100; index += 1) {
    timesArray.push({ value: `${index + 1}`, id: index });
  }
  return timesArray;
};

export const generateDateWithoutTimezone = (dateAndTimeOptions: Date | number) => {
  const date = new Date(dateAndTimeOptions);
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours())).toUTCString();
};

export const generateDateWithTimezone = (requestedDate: Date | number, hours: number, buildingTimezone: string) => {
  const pickedTimeToDate = set(requestedDate, { hours });
  return zonedTimeToUtc(pickedTimeToDate, buildingTimezone).toISOString();
};
