import React from 'react';
import toast from 'react-hot-toast';
import { useRemoveClientCreditCardMutation } from 'store/client/client.api';
import useTranslation from 'shared/useTranslation';
import CardDetailsPopup from '../SummaryPayment/organisms/CardDetailsPopup';
import RemoveCardConfirmationPopup from '../SummaryPayment/organisms/RemoveCardConfirmationPopup';
import AddNewCardPopup from '../SummaryPayment/organisms/AddNewCardPopup';
import { SummaryPaymentPopupsProps } from './types';

const SummaryPaymentPopups = ({
  cardData,
  isAddNewCardOpen,
  isCardDetailsOpen,
  isCardRemoveOpen,
  onCardDetailsClose,
  onRemoveCardClose,
  onRemoveCardOpen,
  onAddNewCardClose,
}: SummaryPaymentPopupsProps) => {
  const [translations] = useTranslation(['toast.success.title', 'toast.failure.title'] as const);
  const [removeCreditCard, { isLoading }] = useRemoveClientCreditCardMutation();

  const onCreditCardRemove = async () => {
    if (!cardData?.id) return;

    try {
      await removeCreditCard(cardData.id).unwrap();
      onCardDetailsClose();
      onRemoveCardClose();
      toast.success(translations.toastSuccessTitle);
    } catch {
      toast.error(translations.toastFailureTitle);
    }
  };

  return (
    <>
      {cardData ? (
        <>
          <CardDetailsPopup
            cardDetail={cardData}
            open={isCardDetailsOpen}
            onClose={onCardDetailsClose}
            onRemoveCardClick={onRemoveCardOpen}
          />
          <RemoveCardConfirmationPopup
            open={isCardRemoveOpen}
            onClose={onRemoveCardClose}
            onRemoveCard={onCreditCardRemove}
            isLoading={isLoading}
          />
        </>
      ) : null}
      <AddNewCardPopup open={isAddNewCardOpen} onClose={onAddNewCardClose} />
    </>
  );
};

export default SummaryPaymentPopups;
