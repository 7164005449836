import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'UI';
import { logout } from 'store/auth/auth.actions';
import { useAppDispatch } from 'hooks/hooks';

const App = () => {
  const dispatch = useAppDispatch();
  const onLogoutClick = () => dispatch(logout());
  return (
    <Link to="/">
      <Button variant="contained" color="primary" fullWidth onClick={onLogoutClick}>
        logout
      </Button>
    </Link>
  );
};

export default App;
