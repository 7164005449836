import styled from '@emotion/styled';
import COLORS from 'styles/colors';

export const Container = styled.div``;

interface StepProps {
  isPassed: boolean;
}

export const StepLine = styled.div`
  width: 100%;
  padding: 16px 112px 16px 112px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 768px) {
    justify-content: flex-start;
    padding: 16px;
    align-items: center;
  }

  @media (max-width: 1100px) {
    padding: 16px 48px;
  }
`;

export const Step = styled.span<StepProps>`
  font-size: 12px;
  line-height: 24px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 16px;
  color: ${({ isPassed }) => (isPassed ? COLORS.bombay : COLORS.paleSky)};

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;
