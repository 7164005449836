import TokenService from 'services/token.service';
import * as endpoints from '../apiUtils/endpoints';
import { api } from '../apiUtils/api';
import { LoginResponse, UserCredentials } from './auth.types';
import UserService from '../../services/user.service';

export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, UserCredentials>({
      query: (credentials) => ({
        url: endpoints.login,
        method: 'POST',
        body: credentials,
      }),
      async onQueryStarted(token, { queryFulfilled }) {
        const {
          data: { refreshToken, accessToken },
        } = await queryFulfilled;
        TokenService.updateLocalAccessToken(accessToken);
        TokenService.updateLocalRefreshToken(refreshToken);
      },
    }),
    loginByToken: builder.mutation<LoginResponse, void>({
      query: () => `${endpoints.refreshToken}/client`,
      async onQueryStarted(token, { queryFulfilled }) {
        const {
          data: { refreshToken, accessToken, user },
        } = await queryFulfilled;
        TokenService.updateLocalAccessToken(accessToken);
        TokenService.updateLocalRefreshToken(refreshToken);
        UserService.updateLocalUser(user);
      },
    }),
  }),
  overrideExisting: true,
});

export const { useLoginMutation, useLoginByTokenMutation } = authApi;
