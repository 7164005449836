import { isEmpty, trim } from 'lodash';

export function isStringEmptyTrimmed(str: string) {
  return isEmpty(trim(str));
}

export function displayPriceFormatted(price: string | number) {
  if (Number.isNaN(price)) {
    return '$ 0';
  }
  return `$ ${Number(price).toFixed(2)}`;
}
