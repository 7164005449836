export interface LoginFormProps {}

export type LoginFormFields = {
  email: string;
  password: string;
};

export type LocationProps = {
  state: {
    from: Location;
  };
};

export const TOKEN_SEARCH_NAME = 'token';
