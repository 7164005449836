import { useContext, useState } from 'react';
import SummaryPaymentFooter from 'Domains/Step/molecules/SummaryPaymentFooter';
import { WidgetContext } from 'Pages/Widget/Widget.constants';
import { WidgetActionType } from 'Pages/Widget/Widget.store';
import * as stripeJs from '@stripe/stripe-js';
import { SummaryStepProps } from './types';
import * as S from './styles';
import SummaryPayment from './organisms/SummaryPayment';
import SummaryData from './organisms/SummaryData';
import { CREDIT_CARD_ID_NONE } from './constants';
import { useGetPaymentCards, useProceedPayment } from './hooks';
import { useGetTotalPrice } from '../../hooks/widget';

const SummaryStep = ({ steps }: SummaryStepProps) => {
  const { dispatch } = useContext(WidgetContext);
  const { isFetching, paymentCards, selectedPaymentCardId, initialDefaultCard, onCardSelect } = useGetPaymentCards();

  const proceedPayment = useProceedPayment({
    onError: () => {
      onCardSelect(initialDefaultCard);
    },
  });
  const price = useGetTotalPrice();

  const [isDisabled, setIsDisabled] = useState(true);
  const [isProceedPaymentFetching, setIsProceedPaymentFetching] = useState(false);

  // Note: If price is equal to 0 we should enable the button,
  // since the `credit card` fields are hidden when price is 0
  const isPaymentButtonDisabled = selectedPaymentCardId === CREDIT_CARD_ID_NONE && isDisabled && price > 0;

  const handleGoBack = () => dispatch({ type: WidgetActionType.PREVIOUS_STEP });

  const onCardInfoChange = (event: stripeJs.StripeCardElementChangeEvent) => {
    if (event.complete) {
      setIsDisabled(false);
      return;
    }
    if (event.error) {
      setIsDisabled(true);
    }
  };

  const onProceedPaymentClick = async () => {
    if (isPaymentButtonDisabled) {
      return;
    }
    setIsProceedPaymentFetching(true);
    await proceedPayment(selectedPaymentCardId);
    setIsProceedPaymentFetching(false);
  };

  return (
    <S.Wrapper>
      <S.Container>
        <SummaryData />
        <SummaryPayment
          isLoading={isFetching}
          onCardInfoChange={onCardInfoChange}
          onCardSelect={onCardSelect}
          paymentCards={paymentCards}
          price={price}
          selectedPaymentCardId={selectedPaymentCardId}
          steps={steps}
        />
      </S.Container>
      <SummaryPaymentFooter
        loading={isProceedPaymentFetching}
        isDisabled={isPaymentButtonDisabled}
        onGoBackClick={handleGoBack}
        onProceedPaymentClick={onProceedPaymentClick}
      />
    </S.Wrapper>
  );
};

export default SummaryStep;
