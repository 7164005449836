import styled from '@emotion/styled';
import COLORS from 'styles/colors';
import Carousel from 'react-material-ui-carousel';

interface SelectedProps {
  isSelected?: boolean;
}

export const TimeCarousel = styled(Carousel)`
  display: flex;
  margin-top: 20px;
  margin-bottom: 32px;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 8px 56px;

  & .MuiButtonBase-root {
    margin: 0;
    background-color: transparent !important;
  }

  // TODO: YK:  I can't find other way overflow hidden this carousel, & > div, but I sure that it could be done better
  & > div {
    overflow: hidden;
  }

  @media (max-width: 420px) {
    padding: 8px 40px;
  }
`;

export const TimeList = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

export const TimeItem = styled.div<SelectedProps & { disabled?: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  font-weight: 500;
  align-items: center;
  flex-grow: 1;
  padding: 8px;
  width: 23%;
  border-radius: 4px;
  background: ${({ disabled }) => (disabled ? COLORS.mercury : COLORS.white)};
  text-align: center;

  &:hover {
    box-shadow: 0 4px 10px ${COLORS.blackSqueeze};
  }

  @media (max-width: 768px) {
    padding: 16.5px 11px;
    font-size: 10px;
  }
`;

export const Selected = styled.div<SelectedProps>`
  display: ${({ isSelected }) => (isSelected ? 'flex' : 'none')};
  position: absolute;
  width: 20px;
  height: 18px;
  border-bottom-left-radius: 8px;
  border-top-right-radius: 5px;
  right: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS.cornflowerBlue};
`;

export const SelectedIcon = styled.img`
  width: 12px;
`;
