import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { Button } from 'UI';
import colors from 'styles/colors';

export const PaymentCardsWrapper = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 auto;
  max-width: 100%;
  overflow: hidden;
  width: 100%;

  & > #saved-credit-card:not(:nth-last-child(2)) {
    border-bottom: 1px solid ${colors.grey20};
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 50%;
  width: 100%;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

export const TextNote = styled.div`
  background: ${colors.cornflowerBlueOpacity10};
  border-radius: 8px;
  color: ${colors.cornflowerBlue};
  display: none;
  font-size: 12px;
  font-weight: 500;
  padding: 16px;

  @media (max-width: 768px) {
    display: inline-block;
  }
`;

export const SummaryInformation = styled(Typography)`
  font-size: 12px;
  color: ${colors.grey60};
  text-align: center;
  font-weight: 500;
  white-space: break-spaces;
  display: none;

  @media (max-width: 768px) {
    margin: 12px 0;
    display: inline-block;
  }
`;

export const AddNewCard = styled(Button)`
  display: none;

  @media (max-width: 768px) {
    align-items: center;
    background-color: ${colors.white};
    border-radius: 8px;
    box-shadow: 0px 4px 12px 0px ${colors.athensGray2};
    display: flex;
    justify-content: space-between;
    margin: 8px 0;
    padding: 12px 21px 12px 28px;
  }
`;

export const AddNewCardText = styled.div`
  color: ${colors.grey80};
  font-family: Outfit, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 16px;
  margin-right: auto;
`;
