import styled from '@emotion/styled';
import { Typography } from '@mui/material';

export const Container = styled(Typography)`
  font-size: 32px;
  font-weight: 500;
  line-height: 1.5;
  padding: 0 16px;

  @media (max-width: 768px) {
    font-size: 24px;
    margin-left: 0px;
    margin-top: 0px;
  }
`;
