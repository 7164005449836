import { TFunction } from 'react-i18next';
import { RecurrenceType } from '../Pages/Widget/Widget.types';
import { AppointmentType, isAppointmentTypeOneTime } from './recurring';

const TRANSLATION_MAP = {
  [RecurrenceType.Daily]: 'common.booking.recurrence.type.per.day',
  [RecurrenceType.Weekly]: 'common.booking.recurrence.type.per.week',
  [RecurrenceType.Every2Weeks]: 'common.booking.recurrence.type.per.2weeks',
  [RecurrenceType.Every3Weeks]: 'common.booking.recurrence.type.per.3weeks',
  [RecurrenceType.Every4Weeks]: 'common.booking.recurrence.type.per.4weeks',
};

const getPriceForRepeatType = (
  t: TFunction<'translation'>,
  repeatMode?: RecurrenceType,
  appointmentType?: AppointmentType,
) => {
  if (!repeatMode || isAppointmentTypeOneTime(appointmentType)) return undefined;

  return t(TRANSLATION_MAP[repeatMode] ?? 'common.label.empty');
};

export default getPriceForRepeatType;
