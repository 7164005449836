import { AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import React from 'react';
import { AccordionProps } from './Accordion.types';
import * as S from './Accordion.styles';

const Accordion = ({ children, title, ...rest }: AccordionProps) => (
  <S.Container {...rest}>
    <AccordionSummary
      expandIcon={<img src={`${process.env.PUBLIC_URL}/icons/expand-arrow.svg`} />}
      aria-controls="panel1a-content"
      id="panel1a-header"
    >
      <Typography>{title}</Typography>
    </AccordionSummary>
    <AccordionDetails>{children}</AccordionDetails>
  </S.Container>
);

export default Accordion;
