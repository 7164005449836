import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import colors from 'styles/colors';

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  margin: 0 auto;
`;

export const Title = styled(Typography)`
  color: ${colors.grey60};
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  padding: 0 8px;
`;

export const Subtitle = styled(Typography)`
  color: ${colors.grey60};
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  padding: 0 8px;
`;
