import React, { useContext, useEffect } from 'react';
import { WidgetContext } from 'Pages/Widget/Widget.constants';
import { WidgetActionType } from 'Pages/Widget/Widget.store';
import PageNoData from 'UI/PageNoData';
import { selectIndustries } from 'store/industry/selectors';
import { useAppSelector } from 'hooks/hooks';
import useTranslation from 'shared/useTranslation';
import { getPriceLabel } from 'shared/getAsLowAsPrice';
import * as S from './styles';
import IndustryComponent from './molecules/IndustryComponent';
import { getIndustryFormatted } from './utils';

const IndustryStep = () => {
  const industries = useAppSelector(selectIndustries);
  const { state, dispatch } = useContext(WidgetContext);
  const { selectedIndustryId } = state;

  const [translations] = useTranslation([
    'widget.step.price.pre',
    'widget.step.industry.empty.title',
    'widget.step.industry.failed.title',
  ] as const);

  useEffect(() => {
    dispatch({
      type: WidgetActionType.RESTART_WIDGET_CREATION,
    });
  }, [dispatch]);

  const onIndustryClick = (industryId: number) => {
    dispatch({ type: WidgetActionType.UPDATE_INDUSTRY_STEP, payload: { industryId } });
  };

  if (!industries) {
    return <PageNoData title={translations.widgetStepIndustryFailedTitle} />;
  }

  if (industries.length === 0) {
    return <PageNoData title={translations.widgetStepIndustryEmptyTitle} />;
  }

  return (
    <S.Wrapper>
      {getIndustryFormatted(industries).map((industry) => (
        <IndustryComponent
          key={industry.id}
          id={industry.id}
          isSelected={industry.id === selectedIndustryId}
          name={industry.name}
          price={getPriceLabel(translations.widgetStepPricePre, industry.asLowPrice)}
          svgUrl={industry.svgUrl}
          onClick={onIndustryClick}
        />
      ))}
    </S.Wrapper>
  );
};

export default IndustryStep;
