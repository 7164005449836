import styled from '@emotion/styled';
import logo from 'icons/el-logo.svg';
import COLORS from 'styles/colors';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: ${COLORS.catskillWhite};
  overflow: auto;
  flex-direction: column;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 30px 0px 30px 78px;
  align-items: center;
  background-color: ${COLORS.white};

  @media (max-width: 768px) {
    padding: 24px;
  }
`;

export const LogoWrapper = styled.div`
  background-image: url(${logo});
  height: 29px;
  width: 124px;
`;

export const Summary = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 40px;
  justify-content: space-around;

  @media (max-width: 768px) {
    margin-right: 0px;
    align-items: flex-end;
  }
`;

export const SummaryText = styled.span`
  color: ${COLORS.grey80};
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;

  @media (max-width: 768px) {
    font-size: 10px;
    line-height: 12px;
  }
`;

export const SummaryValue = styled.span`
  color: ${COLORS.grey80};
  font-size: 18px;
  font-weight: 600;

  @media (max-width: 768px) {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
  }
`;

export const BodyContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;

  padding: 16px 78px 0 78px;
  overflow: hidden auto;

  @media (max-width: 768px) {
    padding: 0 16px;
  }

  @media (max-width: 1100px) {
    padding: 0 32px;
  }
`;
