import styled from '@emotion/styled';

export const Container = styled.div`
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
  padding: 0 16px;

  @media (max-width: 768px) {
    padding: 0;
  }
`;
