import { SummaryPaymentProps } from 'Domains/StripePaymentForm/StripePaymentForm.types';
import { useState } from 'react';
import { CreditCardInfo } from 'store/client/client.types';
import * as S from './styles';
import CardsList from '../../molecules/CardsList';
import SummaryPaymentFooter from '../../molecules/SummaryPaymentFooter';
import SummaryPaymentPopups from '../SummaryPaymentPopups';

const SummaryPayment = ({
  isLoading,
  onCardInfoChange,
  onCardSelect,
  paymentCards,
  price,
  selectedPaymentCardId,
}: SummaryPaymentProps) => {
  const [isCardDetailsOpen, setIsCardDetailsOpen] = useState(false);
  const [isCardRemoveOpen, setIsCardRemoveOpen] = useState(false);
  const [isAddNewCardOpen, setIsAddNewOpen] = useState(false);

  const selectedCardData = paymentCards.find((paymentCard) => paymentCard.creditCardId === selectedPaymentCardId);

  const toggleCardDetailsPopupOpen = () => {
    setIsCardDetailsOpen((isOpen) => !isOpen);
  };

  const toggleRemoveCardPopupOpen = () => {
    setIsCardRemoveOpen((isOpen) => !isOpen);
  };

  const toggleAddNewCardPopupOpen = () => {
    setIsAddNewOpen((isOpen) => !isOpen);
  };

  return (
    <>
      <S.Wrapper>
        <CardsList
          isLoading={isLoading}
          paymentCards={paymentCards}
          price={price}
          selectedPaymentCardId={selectedPaymentCardId}
          onCardSelect={onCardSelect}
          onCardInfoChange={onCardInfoChange}
          onCardDetailsOpen={toggleCardDetailsPopupOpen}
        />
        <SummaryPaymentFooter price={price} onAddNewCardClick={toggleAddNewCardPopupOpen} />
      </S.Wrapper>
      <SummaryPaymentPopups
        cardData={selectedCardData as CreditCardInfo}
        isAddNewCardOpen={isAddNewCardOpen}
        isCardDetailsOpen={isCardDetailsOpen}
        isCardRemoveOpen={isCardRemoveOpen}
        onCardDetailsClose={toggleCardDetailsPopupOpen}
        onRemoveCardClose={toggleRemoveCardPopupOpen}
        onRemoveCardOpen={toggleRemoveCardPopupOpen}
        onAddNewCardClose={toggleAddNewCardPopupOpen}
      />
    </>
  );
};

export default SummaryPayment;
