import { IconSelectedProps } from './types';
import * as S from './styles';

const ICON_SELECTED_URL = `${process.env.PUBLIC_URL}/icons/tick-small.svg`;

const IconSelected = ({ selected }: IconSelectedProps) => (
  <S.Wrapper selected={selected}>
    <S.Icon src={ICON_SELECTED_URL} />
  </S.Wrapper>
);

export default IconSelected;
