import { User } from 'store/auth/auth.types';

const LOCAL_USER_KEY = 'user-widget';

const getLocalUser = () => {
  const user = localStorage.getItem(LOCAL_USER_KEY);
  return user && JSON.parse(user);
};

const updateLocalUser = (user: User) => {
  localStorage.setItem(LOCAL_USER_KEY, JSON.stringify(user));
};

const deleteLocalUser = () => {
  localStorage.removeItem(LOCAL_USER_KEY);
};

const UserService = {
  getLocalUser,
  updateLocalUser,
  deleteLocalUser,
};

export default UserService;
